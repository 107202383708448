export const WORDS = [
  'تخریب',
  'ابزار',
  'سابقه',
  'آسمان',
  'رضایت',
  'شناخت',
  'حکومت',
  'قاچاق',
  'واقعی',
  'رسانه',
  'آزادی',
  'اتحاد',
  'موضوع',
  'سفارت',
  'تولید',
  'طراحی',
  'زندگی',
  'سینما',
  'سلامت',
  'امسال',
  'توصیف',
  'استاد',
  'فضایی',
  'سرویس',
  'هزینه',
  'اخبار',
  'تهران',
  'نمایش',
  'قانون',
  'مرکزی',
  'پایان',
  'نگران',
  'ضروری',
  'حمایت',
  'تحقیق',
  'برنده',
  'مرحله',
  'محترم',
  'استان',
  'مختلف',
  'درمان',
  'مهندس',
  'سنگین',
  'مدرسه',
  'دولتی',
  'تسلیم',
  'اسفند',
  'تمرین',
  'آزمون',
  'خسارت',
  'طبیعت',
  'دخالت',
  'عضویت',
  'آگاهی',
  'سیاست',
  'فرنگی',
  'مستقل',
  'توافق',
  'جایزه',
  'تقویت',
  'اجازه',
  'جریان',
  'تامین',
  'جنایت',
  'تحلیل',
  'منطقه',
  'زباله',
  'مظلوم',
  'حادثه',
  'شماره',
  'البته',
  'جمعیت',
  'اسلام',
  'وظیفه',
  'امنیت',
  'تجاوز',
  'کنترل',
  'منتشر',
  'وضعیت',
  'متخصص',
  'هدایت',
  'فرهنگ',
  'تاریخ',
  'فولاد',
  'تصمیم',
  'موجود',
  'مقاله',
  'مشترک',
  'طبیعی',
  'بیمار',
  'کیفیت',
  'بحران',
  'دیدار',
  'همایش',
  'پروژه',
  'افراد',
  'صندوق',
  'معاون',
  'محصول',
  'رقابت',
  'فهرست',
  'خودرو',
  'باران',
  'اصلاح',
  'مساحت',
  'تغییر',
  'شخصیت',
  'پایین',
  'داخلی',
  'گسترش',
  'رابطه',
  'ریاست',
  'وسیله',
  'تجارت',
  'پرورش',
  'آلوده',
  'عدالت',
  'احساس',
  'هنگام',
  'تربیت',
  'تالار',
  'خاطره',
  'کمیته',
  'نوروز',
  'بنزین',
  'سراسر',
  'تحریم',
  'وزارت',
  'میراث',
  'انسان',
  'منظور',
  'خرداد',
  'زلزله',
  'ولایت',
  'توزیع',
  'پرواز',
  'انرژی',
  'مستمر',
  'توسعه',
  'گذشته',
  'ماشین',
  'حداقل',
  'دیروز',
  'پژوهش',
  'تشخیص',
  'پنهان',
  'آینده',
  'تهدید',
  'نقاشی',
  'تصویر',
  'مربوط',
  'جامعه',
  'میزان',
  'حفاظت',
  'تفاوت',
  'رادیو',
  'فارسی',
  'فلسفه',
  'تعریف',
  'صورتی',
  'نزدیک',
  'نمونه',
  'انجمن',
  'فرزند',
  'همدان',
  'تفاهم',
  'هشدار',
  'آخرین',
  'گزارش',
  'بهینه',
  'حقیقت',
  'نشریه',
  'ایمان',
  'موسسه',
  'اداره',
  'تجربه',
  'روسیه',
  'ایران',
  'خصوصی',
  'روستا',
  'اهداف',
  'تعامل',
  'جزیره',
  'ترجمه',
  'نظارت',
  'تحصیل',
  'فراهم',
  'پدیده',
  'اولین',
  'ستاره',
  'امروز',
  'ورزشی',
  'نتیجه',
  'ذخیره',
  'دستور',
  'گفتگو',
  'معادل',
  'بودجه',
  'دقیقه',
  'تصویب',
  'ارسال',
  'مخالف',
  'سرباز',
  'تشکیل',
  'سیستم',
  'اهمیت',
  'برابر',
  'علاقه',
  'ایجاد',
  'همراه',
  'مراسم',
  'میدان',
  'معاصر',
  'دیوان',
  'زندان',
  'عشایر',
  'بازار',
  'کوتاه',
  'مشاور',
  'پیاده',
  'نهایت',
  'نظامی',
  'اروپا',
  'ترتیب',
  'بررسی',
  'مناسب',
  'نهایی',
  'محکوم',
  'نامزد',
  'سیمان',
  'حاشیه',
  'آموزش',  
]