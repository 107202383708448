export const VALID_GUESSES = [
  'آئورت',
  'آبابا',
  'آباجی',
  'آبادی',
  'آبپاش',
  'آب‌پز',
  'آبتنی',
  'آبخور',
  'آبخیز',
  'آبدار',
  'آبدان',
  'آبدره',
  'آبرام',
  'آبراه',
  'آبرفت',
  'آبرنگ',
  'آبرون',
  'آبریز',
  'آبستن',
  'آبسنگ',
  'آبشار',
  'آبکند',
  'آبگذر',
  'آبگرم',
  'آبگون',
  'آبگیر',
  'آبمرغ',
  'آبنوس',
  'آبهای',
  'آبوجا',
  'آب‌یخ',
  'آپاچی',
  'آپریل',
  'آپلند',
  'آپلیت',
  'آپولو',
  'آتشین',
  'آتلیه',
  'آتوپی',
  'آجدار',
  'آخرین',
  'آخشیج',
  'آخوند',
  'آدامس',
  'آدمکش',
  'آدمیت',
  'آدینه',
  'آذرخش',
  'آذرین',
  'آذوقه',
  'آذینی',
  'آرامش',
  'آرامی',
  'آرایش',
  'آرایه',
  'آرپژو',
  'آرتور',
  'آرسته',
  'آرشیو',
  'آرکئن',
  'آرگون',
  'آرگیل',
  'آرمان',
  'آروین',
  'آرویو',
  'آریای',
  'آزاده',
  'آزادی',
  'آزبست',
  'آزردن',
  'آزرده',
  'آزگار',
  'آزمند',
  'آزمون',
  'آزوند',
  'آژانس',
  'آسانا',
  'آسانی',
  'آسایش',
  'آستان',
  'آستین',
  'آسمان',
  'آسهای',
  'آسودن',
  'آسوده',
  'آسیاب',
  'آسیای',
  'آسیبی',
  'آسیمه',
  'آشامی',
  'آشپزی',
  'آشرام',
  'آشغال',
  'آشفته',
  'آشکار',
  'آشیان',
  'آغازش',
  'آغازه',
  'آغازی',
  'آغشتن',
  'آغشته',
  'آفازی',
  'آفاکی',
  'آفتاب',
  'آفرین',
  'آکریل',
  'آکسان',
  'آکسون',
  'آکمند',
  'آکندن',
  'آکنده',
  'آگاتا',
  'آگاهی',
  'آگوست',
  'آلاله',
  'آلایش',
  'آلبوم',
  'آلبیت',
  'آلرژی',
  'آلزاس',
  'آلفرد',
  'آلکیل',
  'آلمان',
  'آلوئه',
  'آلوچه',
  'آلودن',
  'آلوده',
  'آلونک',
  'آلیاژ',
  'آماده',
  'آماری',
  'آمایش',
  'آمپول',
  'آمخته',
  'آمر.)',
  'آمرزش',
  'آمورف',
  'آموزش',
  'آموزه',
  'آموزی',
  'آمیبی',
  'آمیزش',
  'آمیزه',
  'آمیزی',
  'آمینه',
  'آمینو',
  'آنتون',
  'آنجلس',
  'آندرس',
  'آنزیم',
  'آنسان',
  'آنگاه',
  'آنوفل',
  'آنوقت',
  'آنیون',
  'آهسته',
  'آهمند',
  'آهنجش',
  'آهنگر',
  'آهنگی',
  'آهنین',
  'آواره',
  'آواری',
  'آوازه',
  'آوازی',
  'آوایی',
  'آوران',
  'آوردن',
  'آورده',
  'آوندی',
  'آویزش',
  'آویشن',
  'آیزاک',
  'آیفون',
  'آینده',
  'آیینه',
  'ئولوگ',
  'ائورت',
  'ابادی',
  'ابالی',
  'ابتدا',
  'ابجوی',
  'ابخست',
  'ابخور',
  'ابخیز',
  'ابداً',
  'ابدار',
  'ابداع',
  'ابدره',
  'ابدوغ',
  'ابدیت',
  'ابراز',
  'ابرام',
  'ابراه',
  'ابرفت',
  'ابرنگ',
  'ابرون',
  'ابروی',
  'ابریز',
  'ابریق',
  'ابزار',
  'ابزنی',
  'ابستن',
  'ابسرا',
  'ابشار',
  'ابشور',
  'ابطال',
  'ابعاد',
  'ابقاء',
  'ابکره',
  'ابکش‌',
  'ابکند',
  'ابگذر',
  'ابگون',
  'ابگیر',
  'ابلاغ',
  'ابلهی',
  'ابلیس',
  'ابنوس',
  'ابنیه',
  'ابهام',
  'ابهای',
  'ابیای',
  'اپرای',
  'اپرون',
  'اپولو',
  'اپیکا',
  'اتابک',
  'اتاشه',
  'اتاقک',
  'اتاقه',
  'اتاقی',
  'اتاوا',
  'اتپسی',
  'اتحاد',
  'اتخاذ',
  'اترنت',
  'اتریش',
  'اتساع',
  'اتشین',
  'اتصاف',
  'اتصال',
  'اتفاق',
  'اتکاء',
  'اتلاف',
  'اتمام',
  'اتهام',
  'اتوره',
  'اتیکت',
  'اتیلن',
  'اثاثه',
  'اثبات',
  'اثرات',
  'اثصول',
  'اثناء',
  'اثیری',
  'اجابت',
  'اجاره',
  'اجازت',
  'اجازه',
  'اجامر',
  'اجبار',
  'اجحاف',
  'اجحیا',
  'اجداد',
  'اجراء',
  'اجرام',
  'اجرای',
  'اجزاء',
  'اجزای',
  'اجساد',
  'اجسام',
  'اجلاس',
  'اجماع',
  'اجمال',
  'اجناس',
  'اجنبی',
  'احاطه',
  'احاله',
  'احجار',
  'احداث',
  'احراز',
  'احزاب',
  'احساس',
  'احسان',
  'احسنت',
  'احشاء',
  'احشام',
  'احضار',
  'احکام',
  'احلام',
  'احمق‌',
  'احوال',
  'احولی',
  'احیاء',
  'احیای',
  'اخاذی',
  'اخاوت',
  'اخبار',
  'اختری',
  'اختفا',
  'اختقا',
  'اختگی',
  'اختلا',
  'اخراج',
  'اخرمی',
  'اخرین',
  'اخطار',
  'اخفاء',
  'اخلاف',
  'اخلاق',
  'اخلال',
  'اخیرا',
  'اداره',
  'اداری',
  'ادامس',
  'ادامه',
  'ادبار',
  'ادخال',
  'ادرار',
  'ادراک',
  'ادریس',
  'ادعای',
  'ادعیه',
  'ادغام',
  'ادکلن',
  'ادمکش',
  'ادوات',
  'ادوار',
  'ادویه',
  'ادیان',
  'ادیسه',
  'اذرخش',
  'اذرین',
  'اذعان',
  'اذوقه',
  'ارائه',
  'ارابه',
  'ارادت',
  'اراده',
  'ارادی',
  'اراذل',
  'اراضی',
  'ارامش',
  'ارامی',
  'ارایش',
  'ارایه',
  'ارایی',
  'ارباب',
  'اربعه',
  'اربیم',
  'ارتشی',
  'ارتفا',
  'ارتقا',
  'ارثیه',
  'ارجاع',
  'ارجوت',
  'اردکی',
  'اردنی',
  'اردوی',
  'ارزاق',
  'ارزان',
  'ارزشی',
  'ارزن‌',
  'ارزنی',
  'ارزوی',
  'ارزیز',
  'ارسال',
  'ارسطو',
  'ارشاد',
  'ارضاء',
  'ارعاب',
  'ارفاق',
  'ارقام',
  'ارکان',
  'ارکست',
  'ارگات',
  'ارگان',
  'ارمنی',
  'اره‌ی',
  'ارواح',
  'اروپا',
  'ارودن',
  'اروزی',
  'اروند',
  'اریبی',
  'اریتم',
  'ازآنش',
  'ازاتش',
  'ازاده',
  'ازادی',
  'ازارث',
  'ازاره',
  'ازاله',
  'ازاین',
  'ازبکی',
  'ازپیش',
  'ازترک',
  'ازتوی',
  'ازتیر',
  'ازجان',
  'ازجای',
  'ازجگن',
  'ازجنس',
  'ازچوب',
  'ازحال',
  'ازحفظ',
  'ازراه',
  'ازردن',
  'ازرده',
  'ازروی',
  'ازسمت',
  'ازسنگ',
  'ازشاه',
  'ازشکل',
  'ازطرف',
  'ازعرض',
  'ازعمل',
  'ازکسی',
  'ازگلو',
  'ازگیل',
  'ازلای',
  'ازلیت',
  'ازماه',
  'ازمند',
  'ازمون',
  'ازهوا',
  'ازیاس',
  'اژانس',
  'اژدها',
  'اسائی',
  'اسارت',
  'اساسا',
  'اساسی',
  'اسامه',
  'اسامی',
  'اسانس',
  'اسانی',
  'اسایش',
  'اسایی',
  'اسباب',
  'اسبان',
  'اسبها',
  'اسپات',
  'اسپاش',
  'اسپرز',
  'اسپرس',
  'اسپرک',
  'اسپرم',
  'اسپری',
  'اسپور',
  'اسپوک',
  'اسپین',
  'استات',
  'استاخ',
  'استاد',
  'استار',
  'استاژ',
  'استاش',
  'استاف',
  'استال',
  'استان',
  'استبر',
  'استخر',
  'استرپ',
  'استرس',
  'استرن',
  'استری',
  'استنت',
  'استوا',
  'استوپ',
  'استور',
  'استون',
  'استیک',
  'استیل',
  'استین',
  'اسحله',
  'اسرار',
  'اسراف',
  'اسرای',
  'اسفل‌',
  'اسفنج',
  'اسفند',
  'اسقاط',
  'اسقفی',
  'اسکات',
  'اسکاچ',
  'اسکار',
  'اسکان',
  'اسکلت',
  'اسکله',
  'اسکنر',
  'اسکنه',
  'اسکیت',
  'اسلات',
  'اسلاف',
  'اسلام',
  'اسلاو',
  'اسلحه',
  'اسلم‌',
  'اسلوب',
  'اسلیپ',
  'اسمان',
  'اسمره',
  'اسمزی',
  'اسمیر',
  'اسمیم',
  'اسناد',
  'اسنان',
  'اسهال',
  'اسودن',
  'اسوده',
  'اسیاب',
  'اسیای',
  'اسیدی',
  'اسیری',
  'اشاره',
  'اشاعه',
  'اشباع',
  'اشبیل',
  'اشپزی',
  'اشتها',
  'اشخاص',
  'اشراف',
  'اشراق',
  'اشعار',
  'اشغال',
  'اشفتن',
  'اشفته',
  'اشکار',
  'اشکاف',
  'اشکال',
  'اشکفت',
  'اشکلک',
  'اشکوب',
  'اشنان',
  'اشوری',
  'اشیاء',
  'اشیان',
  'اشیای',
  'اصابت',
  'اصالت',
  'اصرار',
  'اصطبل',
  'اصطلا',
  'اصلاً',
  'اصلاح',
  'اصلیت',
  'اصناف',
  'اصوات',
  'اصولا',
  'اصولی',
  'اضافه',
  'اضافی',
  'اضداد',
  'اضرار',
  'اضلاع',
  'اطاعت',
  'اطاقک',
  'اطاقی',
  'اطاله',
  'اطراف',
  'اطعام',
  'اطعمه',
  'اط‌فا',
  'اطفال',
  'اطلاع',
  'اطلاق',
  'اط‌لس',
  'اطلسی',
  'اطناب',
  'اظهار',
  'اعاده',
  'اعاشه',
  'اعانت',
  'اعانه',
  'اعتاب',
  'اعتنا',
  'اعجاب',
  'اعجاز',
  'اعداد',
  'اعدام',
  'اعراب',
  'اعراض',
  'اعزاز',
  'اعزام',
  'اعسار',
  'اعشار',
  'اعصاب',
  'اعصار',
  'اعضاء',
  'اعضای',
  'اعطاء',
  'اعطای',
  'اعظ‌م',
  'اعقاب',
  'اعلاء',
  'اعلام',
  'اعلان',
  'اعلای',
  'اعماق',
  'اعمال',
  'اعیان',
  'اغاجی',
  'اغازی',
  'اغاسی',
  'اغذیه',
  'اغراق',
  'اغفال',
  'اغماء',
  'اغماض',
  'اغنام',
  'اغنای',
  'اغنیا',
  'اغواء',
  'افاده',
  'افتاب',
  'افتاپ',
  'افتان',
  'افترا',
  'افدرا',
  'افراد',
  'افراز',
  'افراس',
  'افراط',
  'افرای',
  'افروز',
  'افرین',
  'افزار',
  'افزون',
  'افسار',
  'افسری',
  'افسوس',
  'افسون',
  'افشاء',
  'افشان',
  'افشره',
  'افعال',
  'افغان',
  'افقیت',
  'افکار',
  'افکنش',
  'افکنه',
  'افکنی',
  'افگار',
  'افلاس',
  'افلاک',
  'افلیج',
  'افهام',
  'افیون',
  'اقاله',
  'اقامت',
  'اقامه',
  'اقبال',
  'اقتضا',
  'اقتفا',
  'اقدام',
  'اقرار',
  'اقران',
  'اقساط',
  'اقلام',
  'اقلیت',
  'اقلیم',
  'اقناع',
  'اقنوم',
  'اقوال',
  'اقوام',
  'اکتان',
  'اکتاو',
  'اکتبر',
  'اکتفا',
  'اکتین',
  'اکتیو',
  'اکثرا',
  'اکرام',
  'اکران',
  'اکراه',
  'اکسید',
  'اکسیر',
  'اکلیل',
  'اکمال',
  'اکناف',
  'اکنون',
  'اکیدا',
  'اگاهی',
  'اگرچه',
  'اگردو',
  'اگزما',
  'اگزوز',
  'اگزوس',
  'اگزون',
  'الارث',
  'الاسد',
  'الاغ‌',
  'الایش',
  'الایی',
  'الباب',
  'البته',
  'البحر',
  'البدل',
  'البرص',
  'البلع',
  'الجثه',
  'الجدی',
  'الجنب',
  'الجنس',
  'الحاد',
  'الحاق',
  'الحال',
  'الحان',
  'الحلق',
  'الخمر',
  'الدوز',
  'الذئب',
  'الذکر',
  'الذمه',
  'الذنب',
  'الراس',
  'الرژی',
  'الریه',
  'الزام',
  'السنه',
  'السیر',
  'الشکل',
  'الصاق',
  'الصرف',
  'الصوت',
  'الطیب',
  'العلل',
  'العمل',
  'الغاء',
  'الغرض',
  'الفاظ',
  'الفبا',
  'الفنج',
  'الفین',
  'القاء',
  'القای',
  'القدم',
  'القصه',
  'القعر',
  'الکلب',
  'الکلی',
  'الکیل',
  'الگول',
  'الگوی',
  'الماس',
  'المال',
  'المان',
  'المثل',
  'المدت',
  'الملک',
  'الملل',
  'الناس',
  'النسا',
  'النعل',
  'النفس',
  'النگو',
  'النوع',
  'الهام',
  'الهضم',
  'الهه‌',
  'الههء',
  'الوار',
  'الواط',
  'الوان',
  'الوچه',
  'الودن',
  'الوده',
  'الودی',
  'الوقت',
  'الولا',
  'الوهی',
  'الوود',
  'الویی',
  'الیاژ',
  'الیاس',
  'الیاف',
  'الیوت',
  'اماده',
  'امارت',
  'اماره',
  'اماری',
  'اماکن',
  'اماله',
  'امامت',
  'امانت',
  'امانی',
  'امایش',
  'امپلی',
  'امپول',
  'امتلا',
  'امثال',
  'امداد',
  'امدگی',
  'امرار',
  'امراض',
  'امرای',
  'امرزش',
  'امرود',
  'امروز',
  'امریه',
  'امساک',
  'امضاء',
  'امضای',
  'امعان',
  'امکان',
  'امکنه',
  'املاء',
  'املاح',
  'املاک',
  'املای',
  'امنای',
  'امنیت',
  'امنیه',
  'اموات',
  'امواج',
  'اموال',
  'امودن',
  'امورش',
  'اموری',
  'اموزش',
  'اموزی',
  'امیال',
  'امیبی',
  'امیدی',
  'امیزش',
  'امیزه',
  'امیزی',
  'امینه',
  'امینی',
  'انابت',
  'اناری',
  'انبار',
  'انباز',
  'انبان',
  'انبرک',
  'انبری',
  'انبوه',
  'انبیق',
  'انترن',
  'انترو',
  'انتها',
  'انجام',
  'انجمن',
  'انجیر',
  'انجیل',
  'انحنا',
  'انحیر',
  'انداز',
  'اندام',
  'اندان',
  'اندرز',
  'اندری',
  'اندکی',
  'اندود',
  'اندوه',
  'اندیش',
  'اندیک',
  'انرژی',
  'انزال',
  'انزوا',
  'انزیم',
  'انساج',
  'انسان',
  'انسوی',
  'انشاء',
  'انصاف',
  'انطرف',
  'انعام',
  'انفیه',
  'انقال',
  'انقدر',
  'انقضا',
  'انقلا',
  'انکار',
  'انگار',
  'انگشت',
  'انگل‌',
  'انگلی',
  'انگور',
  'انگیز',
  'ان‌ها',
  'انواع',
  'انوشه',
  'انوفل',
  'انیاب',
  'اهاری',
  'اهالی',
  'اهانت',
  'اهداء',
  'اهداف',
  'اهرمن',
  'اهرمی',
  'اهسته',
  'اهلیت',
  'اهمال',
  'اهمیت',
  'اهنکر',
  'اهنگر',
  'اهنگی',
  'اهنین',
  'اهویی',
  'اواخر',
  'اواره',
  'اوازه',
  'اواسط',
  'اوایل',
  'اوایی',
  'اوباش',
  'اوپال',
  'اوتیت',
  'اورات',
  'اوراق',
  'اوران',
  'اوردن',
  'اورده',
  'اورمی',
  'اورند',
  'اورنگ',
  'اوریا',
  'اوریک',
  'اوزان',
  'اوزون',
  'اوستا',
  'اوصیا',
  'اوضاع',
  'اوقات',
  'اوگیا',
  'اولاد',
  'اولکس',
  'اولین',
  'اولیه',
  'اومدی',
  'اوندی',
  'اوهام',
  'اویزه',
  'اویشن',
  'ایالت',
  'ایجاب',
  'ایجاد',
  'ایجاز',
  'ایدون',
  'ایذاء',
  'ایراد',
  'ایران',
  'ایرسا',
  'ایزدی',
  'ایستا',
  'ایستد',
  'ایسوس',
  'ایشان',
  'ایضاً',
  'ایفاء',
  'ایفاد',
  'ایفای',
  'ایقان',
  'ایکاش',
  'ایگلو',
  'ایلام',
  'ایلچی',
  'ایلخی',
  'ایماء',
  'ایمان',
  'ایمنی',
  'ایمیل',
  'اینتر',
  'اینجا',
  'اینده',
  'اینست',
  'اینسو',
  'اینکه',
  'اینگا',
  'ایهام',
  'ایوار',
  'ایوان',
  'ایوتا',
  'ایوسن',
  'ایینه',
  'ایینی',
  'باادب',
  'بابرگ',
  'بابست',
  'بابکت',
  'بابلی',
  'بابند',
  'بابوق',
  'باپشت',
  'باپنج',
  'باتری',
  'باتلا',
  'باتوپ',
  'باتور',
  'باتوم',
  'باتون',
  'باچرب',
  'باچند',
  'باچوب',
  'باحقه',
  'باحیا',
  'باخبر',
  'باختر',
  'باختن',
  'باخرد',
  'باخود',
  'باخوش',
  'باخون',
  'بادام',
  'بادپا',
  'بادپر',
  'بادزن',
  'بادقت',
  'بادکن',
  'بادگل',
  'باده‌',
  'بادها',
  'بادیه',
  'باذکر',
  'باذوق',
  'باران',
  'باربر',
  'بارزش',
  'بارقه',
  'بارکش',
  'بارنج',
  'بارنگ',
  'بارها',
  'باروت',
  'باروح',
  'بارور',
  'باروش',
  'باروک',
  'بارون',
  'باروی',
  'باریک',
  'باریم',
  'بازار',
  'بازال',
  'بازان',
  'بازپس',
  'بازجو',
  'بازده',
  'بازرس',
  'بازرگ',
  'بازکن',
  'بازگو',
  'بازهم',
  'بازور',
  'بازوی',
  'بازیا',
  'بازیخ',
  'باسطه',
  'باسمه',
  'باسیل',
  'باشدت',
  'باشرف',
  'باشرم',
  'باشست',
  'باشکر',
  'باشلا',
  'باشلق',
  'باشند',
  'باشید',
  'باصدا',
  'باصدف',
  'باصره',
  'باصفا',
  'باصول',
  'باطاس',
  'باطری',
  'باط‌ل',
  'باط‌ن',
  'باطنا',
  'باطنی',
  'باطوم',
  'باعرف',
  'باعزم',
  'باغچه',
  'باغضب',
  'بافتن',
  'بافته',
  'بافتی',
  'بافکر',
  'بافهم',
  'باقلا',
  'باقوت',
  'باکال',
  'باکام',
  'باکاه',
  'باکره',
  'باکلا',
  'باکله',
  'باکوس',
  'باکیش',
  'باگوه',
  'بالاس',
  'بالان',
  'بالای',
  'بالجن',
  'بالچه',
  'بالسا',
  'بالشت',
  'بالشی',
  'بالکن',
  'بالنگ',
  'بالها',
  'بالون',
  'بالین',
  'بامبو',
  'بامته',
  'بامگس',
  'باملا',
  'بامهر',
  'بامور',
  'باموم',
  'بامیا',
  'بامید',
  'بامیل',
  'بامیه',
  'بانان',
  'بانتو',
  'باندی',
  'بانظم',
  'بانکی',
  'بانگو',
  'بانمک',
  'بانوج',
  'بانور',
  'بانوک',
  'بانوی',
  'باهمه',
  'باهنر',
  'باهوش',
  'باوری',
  'باوضع',
  'باوفا',
  'بایست',
  'ببازی',
  'بباطن',
  'ببالا',
  'ببدنه',
  'ببرید',
  'ببینی',
  'بپوست',
  'بتاخت',
  'بتانه',
  'بتکده',
  'بتلخی',
  'بته‌ی',
  'بتوان',
  'بتونه',
  'بجانب',
  'بجایی',
  'بجعبه',
  'بجماع',
  'بجناغ',
  'بجنوب',
  'بجنون',
  'بچرخد',
  'بچرخش',
  'بچه‌ء',
  'بچهار',
  'بچهای',
  'بچه‌ی',
  'بچوبی',
  'بچیزی',
  'بچینی',
  'بحالت',
  'بحرام',
  'بحران',
  'بحرکت',
  'بحروف',
  'بحرین',
  'بحقوق',
  'بحیاط',
  'بحیله',
  'بخارا',
  'بخارج',
  'بخاری',
  'بخاطر',
  'بخانه',
  'بخدمت',
  'بخشکی',
  'بخصوص',
  'بخط‌ر',
  'بخواب',
  'بخوبی',
  'بخودش',
  'بخودی',
  'بخورد',
  'بخوشی',
  'بخولق',
  'بداخل',
  'بداخم',
  'بدادن',
  'بدانش',
  'بداهت',
  'بداهه',
  'بدایی',
  'بدبخت',
  'بدبده',
  'بدبین',
  'بدتری',
  'بدجنس',
  'بدحال',
  'بدخلق',
  'بدخیم',
  'بددهن',
  'بدذات',
  'بدراه',
  'بدرجه',
  'بدرخت',
  'بدرشت',
  'بدرقه',
  'بدرود',
  'بدرون',
  'بدریا',
  'بدسته',
  'بدشدن',
  'بدشکل',
  'بدشمن',
  'بدعمل',
  'بدعنق',
  'بدفعل',
  'بدقدم',
  'بدقلق',
  'بدکار',
  'بدکیش',
  'بدمزه',
  'بدنام',
  'بدنما',
  'بدنیا',
  'بدنیت',
  'بدهان',
  'بدهید',
  'بدوره',
  'بدوشی',
  'بدولت',
  'بدویت',
  'بدیعی',
  'بدیمن',
  'بدیهی',
  'بذرات',
  'بذرها',
  'بذغال',
  'برائت',
  'برابر',
  'برادر',
  'براده',
  'برازی',
  'براست',
  'براقی',
  'براکت',
  'برامد',
  'برایی',
  'برباس',
  'بربری',
  'برپشت',
  'برتخت',
  'برتر-',
  'برتری',
  'برتنی',
  'برت‌ی',
  'برجیس',
  'برچسب',
  'برحسب',
  'برحسی',
  'برخاک',
  'برخال',
  'برخوش',
  'بردار',
  'بردگی',
  'بردن‌',
  'بردنی',
  'بررسی',
  'برروی',
  'برزخی',
  'برزدن',
  'برزگر',
  'برزنت',
  'برزیل',
  'برسام',
  'برسوم',
  'برشتن',
  'برشته',
  'برطبق',
  'برطرف',
  'برعکس',
  'برفاب',
  'برفکی',
  'برقزن',
  'برقی‌',
  'برکشش',
  'برگبو',
  'برگچه',
  'برگدم',
  'برگرد',
  'برگزن',
  'برگشت',
  'برگنو',
  'برلین',
  'برمتن',
  'برملا',
  'برملت',
  'برمید',
  'برنئو',
  'برنام',
  'برنجی',
  'برنده',
  'برنزه',
  'برنزی',
  'برنوس',
  'برهان',
  'برهبه',
  'برهمن',
  'برهمه',
  'برهمی',
  'برهنه',
  'بروات',
  'برودت',
  'برونش',
  'برونه',
  'برونی',
  'بروید',
  'برویس',
  'بریان',
  'بریدن',
  'بریده',
  'بریزد',
  'بریزه',
  'بریکت',
  'بزاقی',
  'بزبان',
  'بزحمت',
  'بزدار',
  'بزدلی',
  'بزرخ‌',
  'بزردی',
  'بزرگ‌',
  'بزرگم',
  'بزرگی',
  'بزشتی',
  'بزمجه',
  'بزمین',
  'بزنید',
  'بزودی',
  'بساحل',
  'بسامد',
  'بستاب',
  'بستار',
  'بستری',
  'بستگی',
  'بستن‌',
  'بستنی',
  'بستوه',
  'بسختی',
  'بسرخی',
  'بسرعت',
  'بسرفت',
  'بسستی',
  'بسفید',
  'بسگوی',
  'بسنده',
  'بسودن',
  'بسیار',
  'بسینه',
  'بشارت',
  'بشاشت',
  'بشانه',
  'بشتاب',
  'بشراز',
  'بشریا',
  'بشریت',
  'بشغلی',
  'بشقاب',
  'بشکار',
  'بشگرد',
  'بشمار',
  'بشمال',
  'بشهری',
  'بشوخی',
  'بشیوه',
  'بصاحب',
  'بصرفه',
  'بصفات',
  'بصورت',
  'بصیرت',
  'بطالت',
  'بط‌بع',
  'بط‌رز',
  'بطرزی',
  'بط‌رف',
  'بط‌ری',
  'بطریق',
  'بطعنه',
  'بطلان',
  'بط‌مع',
  'بط‌نی',
  'بط‌ور',
  'بطوری',
  'بظاهر',
  'بظ‌رف',
  'بعالم',
  'بع‌بع',
  'بعبع‌',
  'بعداز',
  'بعشوه',
  'بعلوم',
  'بعهده',
  'بغایت',
  'بغتهً',
  'بغداد',
  'بغذای',
  'بغرنج',
  'بغلظت',
  'بفاعل',
  'بفرقه',
  'بفروش',
  'بفقرا',
  'بقالی',
  'بقایا',
  'بقدرت',
  'بقرار',
  'بقراط',
  'بقرمز',
  'بقسمت',
  'بقط‌ر',
  'بقوزک',
  'بقولا',
  'بقیمت',
  'بکارت',
  'بکاری',
  'بکاسه',
  'بکامه',
  'بکاهش',
  'بکتاب',
  'بکجور',
  'بکرات',
  'بکرزا',
  'بکلمه',
  'بکنار',
  'بگذرد',
  'بگردش',
  'بگردن',
  'بگرمی',
  'بگناه',
  'بگوید',
  'بگیتی',
  'بگیرد',
  'بلادن',
  'بلارد',
  'بلاست',
  'بلاغت',
  'بلاغی',
  'بلاکر',
  'بلانت',
  'بلانچ',
  'بلاهت',
  'بلبلی',
  'بلزوم',
  'بلژیک',
  'بلسان',
  'بلستر',
  'بلغمی',
  'بلغور',
  'بلندا',
  'بلندی',
  'بلوچی',
  'بلوره',
  'بلوری',
  'بلوط‌',
  'بلوطی',
  'بلوکر',
  'بلوکی',
  'بلوند',
  'بماده',
  'بماند',
  'بمبها',
  'بمحال',
  'بمرام',
  'بمردم',
  'بمصرف',
  'بمعنی',
  'بمقام',
  'بموجب',
  'بمورد',
  'بموقع',
  'بمیان',
  'بمیهن',
  'بنابر',
  'بناحق',
  'بناها',
  'بنایی',
  'بنتنت',
  'بنچیک',
  'بندان',
  'بندرت',
  'بندری',
  'بندزن',
  'بندگی',
  'بندند',
  'بندی‌',
  'بندیا',
  'بنرمی',
  'بنزیل',
  'بنزین',
  'بنژاد',
  'بنشین',
  'بنظ‌م',
  'بنفسه',
  'بنفشه',
  'بنفشی',
  'بنقشه',
  'بنگاب',
  'بنگاه',
  'بنلاد',
  'بنه‌ء',
  'بنوبت',
  'بنوبو',
  'بنوعی',
  'بنیاد',
  'بنیان',
  'به…..',
  'بهاتم',
  'بهادر',
  'بهارک',
  'بهاره',
  'بهاری',
  'بهانه',
  'بهاین',
  'بهبود',
  'به‌پر',
  'بهتان',
  'بهتری',
  'بهرام',
  'بهساز',
  'بهشت‌',
  'بهشتی',
  'به‌فن',
  'بهگرا',
  'بهمان',
  'بهمنی',
  'بهمین',
  'به‌هم',
  'بهینه',
  'بوبال',
  'بوبین',
  'بوتان',
  'بوتیک',
  'بوتیل',
  'بوجود',
  'بوحدت',
  'بوحشت',
  'بودار',
  'بودجه',
  'بودگی',
  'بودله',
  'بودن‌',
  'بودند',
  'بودهو',
  'بورات',
  'بوران',
  'بورخس',
  'بورسا',
  'بوریا',
  'بورید',
  'بوریک',
  'بوزدا',
  'بوزون',
  'بوستر',
  'بوسله',
  'بوسنی',
  'بوصیر',
  'بوفچه',
  'بوقوع',
  'بوکسل',
  'بولاک',
  'بولتن',
  'بولرو',
  'بونزن',
  'بویبد',
  'بویژه',
  'بی‌آب',
  'بی‌اب',
  'بیاثر',
  'بیادب',
  'بیارج',
  'بیاشک',
  'بیاصل',
  'بیان‌',
  'بیانی',
  'بیباک',
  'بی‌بر',
  'بی‌بو',
  'بی‌بی',
  'بی‌پا',
  'بی‌پر',
  'بی‌تا',
  'بی‌تب',
  'بیتها',
  'بیثمر',
  'بی‌جا',
  'بیجان',
  'بیجهت',
  'بیچون',
  'بیچیز',
  'بیحال',
  'بی‌حد',
  'بی‌حس',
  'بیحسی',
  'بیحیا',
  'بیخبر',
  'بیختن',
  'بیخرد',
  'بیخود',
  'بیداد',
  'بیدار',
  'بیدست',
  'بی‌دم',
  'بیدها',
  'بیدود',
  'بیدین',
  'بیربط',
  'بیرحم',
  'بیرنگ',
  'بیروت',
  'بیروح',
  'بیرون',
  'بیزار',
  'بی‌زن',
  'بیسبب',
  'بیستم',
  'بی‌سر',
  'بی‌سم',
  'بیسود',
  'بیسیم',
  'بیشاخ',
  'بیشتر',
  'بیشرم',
  'بیشضد',
  'بی‌شک',
  'بیشکک',
  'بیشین',
  'بیصبر',
  'بیصدا',
  'بیضوی',
  'بیضیت',
  'بیطار',
  'بیطرف',
  'بیعفت',
  'بیعقل',
  'بیعیب',
  'بی‌غش',
  'بی‌غم',
  'بیفتد',
  'بی‌فک',
  'بیقدر',
  'بیقید',
  'بیکار',
  'بی‌کس',
  'بیکسو',
  'بی‌کم',
  'بیگاه',
  'بی‌گل',
  'بی‌لب',
  'بیلچه',
  'بیمار',
  'بیمال',
  'بی‌مخ',
  'بیمرز',
  'بیمزد',
  'بیمزه',
  'بیمه‌',
  'بی‌مو',
  'بیمیل',
  'بیناب',
  'بینظم',
  'بینقش',
  'بینگی',
  'بینوا',
  'بینیت',
  'بیهشی',
  'بیهوش',
  'بیوقت',
  'بیوگی',
  'پائین',
  'پابند',
  'پاپهن',
  'پاپوا',
  'پاپوش',
  'پاپیچ',
  'پاتزر',
  'پاتوق',
  'پاتیل',
  'پاچگی',
  'پادآب',
  'پادار',
  'پاداش',
  'پادتن',
  'پادرد',
  'پادری',
  'پادزی',
  'پادگن',
  'پاراف',
  'پاربر',
  'پارتی',
  'پارچه',
  'پارسا',
  'پارسی',
  'پارکت',
  'پارکر',
  'پارگی',
  'پاروی',
  'پاریس',
  'پازرد',
  'پازهر',
  'پاساژ',
  'پاستا',
  'پاسیو',
  'پاشام',
  'پاشله',
  'پاشنا',
  'پاشنه',
  'پاطاس',
  'پافنگ',
  'پاکتی',
  'پاکدل',
  'پاگاه',
  'پاگشا',
  'پاگون',
  'پاگیر',
  'پالار',
  'پالان',
  'پالتو',
  'پالگد',
  'پالما',
  'پالیز',
  'پامال',
  'پانته',
  'پاندا',
  'پانصد',
  'پانما',
  'پاهای',
  'پایاب',
  'پایان',
  'پایای',
  'پاینت',
  'پاییز',
  'پایین',
  'پپتید',
  'پپسین',
  'پتواز',
  'پچ‌پچ',
  'پچواک',
  'پچولی',
  'پخشان',
  'پخمگی',
  'پدران',
  'پدرجد',
  'پدرزن',
  'پدیده',
  'پذیرا',
  'پذیرش',
  'پذیره',
  'پذیری',
  'پراخم',
  'پرانش',
  'پرانی',
  'پرایا',
  'پرباد',
  'پربار',
  'پربرگ',
  'پربلا',
  'پربها',
  'پرپشت',
  'پرپیچ',
  'پرتاب',
  'پرتاپ',
  'پرتره',
  'پرتوش',
  'پرتوی',
  'پرثمر',
  'پرجگر',
  'پرجنب',
  'پرچمی',
  'پرچین',
  'پرحجم',
  'پرحرص',
  'پرحرف',
  'پرخاش',
  'پرخبر',
  'پرخرج',
  'پرخطر',
  'پرخور',
  'پرخون',
  'پردار',
  'پرداز',
  'پردلی',
  'پردهی',
  'پردیس',
  'پرریگ',
  'پرزرق',
  'پرزور',
  'پرسان',
  'پرستش',
  'پرستو',
  'پرستی',
  'پرسشی',
  'پرسنک',
  'پرسنل',
  'پرسود',
  'پرسوس',
  'پرسیج',
  'پرسید',
  'پرشده',
  'پرشکن',
  'پرشور',
  'پرشین',
  'پرصدا',
  'پرطمع',
  'پرقوت',
  'پرکار',
  'پرکنش',
  'پرکنی',
  'پرگار',
  'پرگیر',
  'پرلجن',
  'پرلغت',
  'پرلیت',
  'پرماس',
  'پرمغز',
  'پرموج',
  'پرموی',
  'پرمین',
  'پرنده',
  'پرنمک',
  'پرنور',
  'پرهای',
  'پرهرج',
  'پرهون',
  'پرهیز',
  'پروار',
  'پرواز',
  'پروپن',
  'پروتز',
  'پرورش',
  'پروری',
  'پروزن',
  'پروژه',
  'پروسی',
  'پرولع',
  'پروین',
  'پرویی',
  'پریام',
  'پریان',
  'پریدن',
  'پریده',
  'پریشی',
  'پریود',
  'پریوس',
  'پزاوش',
  'پزشدن',
  'پزشکی',
  'پزنده',
  'پژمان',
  'پژواک',
  'پژوهش',
  'پسابش',
  'پس‌بر',
  'پسبرد',
  'پستاب',
  'پستال',
  'پستان',
  'پستچی',
  'پستنک',
  'پستنگ',
  'پسدمه',
  'پسران',
  'پسرفت',
  'پسرها',
  'پسروی',
  'پسوند',
  'پشه‌ی',
  'پط‌رس',
  'پکتیک',
  'پکیدن',
  'پلازا',
  'پلاست',
  'پلاسر',
  'پلاکت',
  'پلپیک',
  'پلکان',
  'پلکسر',
  'پلکسی',
  'پلنگی',
  'پلوار',
  'پلوور',
  'پلیدی',
  'پلیسه',
  'پلیسی',
  'پلیمر',
  'پلیوس',
  'پمپاژ',
  'پنجاب',
  'پنجاه',
  'پنجره',
  'پنجول',
  'پندار',
  'پندها',
  'پنکیک',
  'پنگان',
  'پنلوپ',
  'پنهان',
  'پنیرک',
  'پنیری',
  'پهرست',
  'پهلوو',
  'پهلوی',
  'پهنای',
  'پوبیس',
  'پوتوس',
  'پوتین',
  'پودری',
  'پودنه',
  'پورتر',
  'پورتو',
  'پورنو',
  'پوره‌',
  'پورین',
  'پوست‌',
  'پوستر',
  'پوستش',
  'پوسته',
  'پوستی',
  'پوشاک',
  'پوشال',
  'پوشان',
  'پوششی',
  'پوشند',
  'پوطاس',
  'پوگان',
  'پولاد',
  'پولاک',
  '.پولک',
  'پولکا',
  'پولکی',
  'پولها',
  'پولیپ',
  'پولیت',
  'پویان',
  'پویچه',
  'پیاپی',
  'پیاده',
  'پیازی',
  'پیاله',
  'پیامد',
  'پیامی',
  'پیانو',
  'پیتزا',
  'پی‌جو',
  'پیچار',
  'پیچال',
  'پیچان',
  'پیچشی',
  'پیچند',
  'پیخال',
  'پیران',
  'پیرزن',
  'پیرهن',
  'پیروز',
  'پیروس',
  'پیروی',
  'پیریت',
  'پیزری',
  'پیشاب',
  'پیشان',
  'پیشتر',
  'پیشرس',
  'پیشرو',
  'پیشکش',
  'پیشگو',
  'پیشوا',
  'پیشین',
  'پیغام',
  'پیکاپ',
  'پیکار',
  'پیکان',
  'پیکره',
  'پیکسل',
  'پیگرد',
  'پیگیر',
  'پیلار',
  'پیلوت',
  'پیلور',
  'پیمان',
  'پینچر',
  'پینکی',
  'پی‌ها',
  'پیوره',
  'پیوست',
  'پیوند',
  'پیونگ',
  'تئاتر',
  'تئوری',
  'تائید',
  'تابان',
  'تابشی',
  'تابعه',
  'تابلو',
  'تابوت',
  'تابون',
  'تابی‌',
  'تاتار',
  'تاتری',
  'تاتوی',
  'تاثیر',
  'تاجور',
  'تاجیک',
  'تاحدی',
  'تاختن',
  'تاخیر',
  'تادیب',
  'تادیه',
  'تاراج',
  'تارتر',
  'تارتن',
  'تارچه',
  'تارزن',
  'تارک‌',
  'تارمک',
  'تارمی',
  'تاروت',
  'تاریخ',
  'تاریک',
  'تازگی',
  'تاسیس',
  'تاسیس',
  'تاشدن',
  'تاشده',
  'تاغان',
  'تافتن',
  'تافته',
  'تاقچه',
  'تاقوس',
  'تاکسی',
  'تاکید',
  'تالاب',
  'تالار',
  'تالان',
  'تالوس',
  'تالیف',
  'تالین',
  'تامین',
  'تانکر',
  'تانیث',
  'تانیک',
  'تانین',
  'تاوان',
  'تاولی',
  'تاویل',
  'تایپه',
  'تایپی',
  'تایکی',
  'تایمر',
  'تایید',
  'تبادل',
  'تبانی',
  'تباهی',
  'تباین',
  'تب‌بر',
  'تبخال',
  'تبختر',
  'تبخیر',
  'تبدبل',
  'تبدیل',
  'تبذیر',
  'تبرئه',
  'تبریخ',
  'تبرید',
  'تبریز',
  'تبریک',
  'تبشیر',
  'تبصبص',
  'تبصره',
  'تبعیت',
  'تبعید',
  'تبعیض',
  'تبلتب',
  'تبلور',
  'تبلیغ',
  'تبنگو',
  'تبیره',
  'تبیین',
  'تپاله',
  'تپنده',
  'تپیدن',
  'تثبیت',
  'تثلیث',
  'تثنیه',
  'تجارت',
  'تجاری',
  'تجاسر',
  'تجانس',
  'تجاهل',
  'تجاوز',
  'تجدید',
  'تجربت',
  'تجربه',
  'تجربی',
  'تجردی',
  'تجزیه',
  'تجلیل',
  'تجمعی',
  'تجملی',
  'تجنیس',
  'تجهیز',
  'تجوید',
  'تجویز',
  'تحدید',
  'تحذیر',
  'تحریر',
  'تحریص',
  'تحریض',
  'تحریف',
  'تحریک',
  'تحریم',
  'تحسین',
  'تحشیه',
  'تحصیل',
  'تحقیر',
  'تحقیق',
  'تحکیم',
  'تحلیف',
  'تحلیل',
  'تحمیل',
  'تحویل',
  'تخحیص',
  'تخدیر',
  'تخریب',
  'تخصصی',
  'تخصیص',
  'تخط‌ی',
  'تخفیف',
  'تخفیف',
  'تخلخل',
  'تخلیه',
  'تخمچه',
  'تخمزا',
  'تخمکی',
  'تخمها',
  'تخمیر',
  'تخمین',
  'تخویف',
  'تخیلش',
  'تخیلی',
  'تداخل',
  'تدارک',
  'تداعی',
  'تدافع',
  'تدافی',
  'تداول',
  'تداوم',
  'تداوی',
  'تدبیر',
  'تدخین',
  'تدریج',
  'تدریس',
  'تدریش',
  'تدفین',
  'تدقیق',
  'تدلیس',
  'تدهین',
  'تدوین',
  'تذبذب',
  'تذکره',
  'تذکیر',
  'تذکیه',
  'تذلیل',
  'تذهیب',
  'ترابر',
  'تراخم',
  'ترادف',
  'ترازو',
  'ترازی',
  'تراست',
  'تراشه',
  'تراشی',
  'تراضی',
  'تراکش',
  'تراکم',
  'ترانس',
  'ترانک',
  'ترانه',
  'تراوا',
  'تراوش',
  'تراوه',
  'تراوی',
  'تربچه',
  'تربیت',
  'تربیع',
  'تربیم',
  'ترتیب',
  'ترجمه',
  'ترجیح',
  'ترحیم',
  'ترخون',
  'ترخیص',
  'ترخیم',
  'تردست',
  'تردید',
  'ترسان',
  'ترسیم',
  'ترشحی',
  'ترشرو',
  'ترشی‌',
  'ترغیب',
  'ترفند',
  'ترفیع',
  'ترقوه',
  'ترقیق',
  'ترکمن',
  'ترکیب',
  'ترکیه',
  'ترمپت',
  'ترمزی',
  'ترمیم',
  'ترهات',
  'ترویج',
  'ترویس',
  'تریاق',
  'تریاک',
  'تریگر',
  'تریلر',
  'تریلی',
  'تزئین',
  'تزاری',
  'تزاید',
  'تزریق',
  'تزکیه',
  'تزلزل',
  'تزویج',
  'تزویر',
  'تزیین',
  'تسالی',
  'تسامح',
  'تساوی',
  'تسبیح',
  'تسپیس',
  'تسجیل',
  'تسخیر',
  'تسریع',
  'تسعیر',
  'تسقیم',
  'تسکین',
  'تسلسل',
  'تسلیت',
  'تسلیح',
  'تسلیم',
  'تسمیه',
  'تسهیل',
  'تسهیم',
  'تسویه',
  'تشابه',
  'تشبیه',
  'تشجیع',
  'تشخیص',
  'تشدید',
  'تشریح',
  'تشریع',
  'تشریق',
  'تشریک',
  'تشعشع',
  'تشکیل',
  'تشنجی',
  'تشنگی',
  'تشویش',
  'تشویق',
  'تشیید',
  'تشییع',
  'تصاحب',
  'تصادف',
  'تصادم',
  'تصاعد',
  'تصحیح',
  'تصدیع',
  'تصدیق',
  'تصریح',
  'تصریف',
  'تصعید',
  'تصفیه',
  'تصمیم',
  'تصنعی',
  'تصنیف',
  'تصوری',
  'تصویب',
  'تصویر',
  'تضریس',
  'تضعیف',
  'تضمین',
  'تضییق',
  'تطابق',
  'تطاول',
  'تطبیق',
  'تطمیع',
  'تطهیر',
  'تظاهر',
  'تعادل',
  'تعارض',
  'تعارف',
  'تعاقب',
  'تعالی',
  'تعامد',
  'تعامل',
  'تعاون',
  'تعبیر',
  'تعبیه',
  'تعجبی',
  'تعجیل',
  'تعداد',
  'تعدیل',
  'تعذیب',
  'تعرضی',
  'تعرفه',
  'تعریض',
  'تعریف',
  'تعریق',
  'تعزیت',
  'تعزیه',
  'تعطیل',
  'تعظیم',
  'تعقلی',
  'تعقیب',
  'تعقید',
  'تعلیق',
  'تعلیل',
  'تعلیم',
  'تعمید',
  'تعمیر',
  'تعمیق',
  'تعمیم',
  'تعهدی',
  'تعویذ',
  'تعویض',
  'تعویق',
  'تعیین',
  'تغابن',
  'تغاری',
  'تغافل',
  'تغاهر',
  'تغذیه',
  'تغسیل',
  'تغلیظ',
  'تغییر',
  'تفاخر',
  'تفاله',
  'تفاهم',
  'تفاوت',
  'تفتیش',
  'تفرجی',
  'تفرعن',
  'تفرقه',
  'تفریح',
  'تفریط',
  'تفریق',
  'تفسیر',
  'تفصیل',
  'تفضیح',
  'تفکری',
  'تفکیک',
  'تفلیس',
  'تفنگی',
  'تفننی',
  'تفهیم',
  'تفویض',
  'تقابل',
  'تقارب',
  'تقارن',
  'تقاضا',
  'تقاطع',
  'تقاعد',
  'تقبیح',
  'تق‌تق',
  'تقدیر',
  'تقدیس',
  'تقدیم',
  'تقریب',
  'تقریر',
  'تقریظ',
  'تقسیم',
  'تقصیر',
  'تقطیر',
  'تقلای',
  'تقلبی',
  'تقلید',
  'تقلیل',
  'تقویت',
  'تقویم',
  'تقیلد',
  'تکاپو',
  'تکاثف',
  'تکاسف',
  'تکامل',
  'تکانه',
  'تکانی',
  'تکاهل',
  'تکاور',
  'تک‌تک',
  'تکثیر',
  'تکذیب',
  'تکرار',
  'تکریر',
  'تکریم',
  'تکزاس',
  'تکشاخ',
  'تکفیر',
  'تکلیس',
  'تکلیف',
  'تکملم',
  'تکمیل',
  'تکنیک',
  'تکهای',
  'تکوین',
  'تکیلا',
  'تگزاس',
  'تلاشی',
  'تلاطم',
  'تلافی',
  'تلاقی',
  'تلالو',
  'تلاوت',
  'تلبیس',
  'تلخیص',
  'تلسکپ',
  'تلسکی',
  'تلفات',
  'تلفکس',
  'تلفنی',
  'تلفیق',
  'تلقیح',
  'تلقین',
  'تلمبه',
  'تلمیح',
  'تلمیذ',
  'تلنگر',
  'تله‌ی',
  'تلوان',
  'تلولو',
  'تلومر',
  'تلیال',
  'تلیله',
  'تمارض',
  'تماس‌',
  'تماسی',
  'تماشا',
  'تماما',
  'تمامی',
  'تمایز',
  'تمایل',
  'تمپان',
  'تمثال',
  'تمثیل',
  'تمجید',
  'تمدید',
  'تمرکز',
  'تمرین',
  'تمساح',
  'تمسخر',
  'تمشیت',
  'تمکین',
  'تمهید',
  'تمیزی',
  'تمییز',
  'تنآسا',
  'تنازع',
  'تناسب',
  'تناسخ',
  'تناسل',
  'تناظر',
  'تنافر',
  'تناقص',
  'تناقض',
  'تناهی',
  'تناوب',
  'تناور',
  'تنبان',
  'تنبلی',
  'تنبور',
  'تنبول',
  'تنبیه',
  'تنپنی',
  'تنتور',
  'تنجیه',
  'تنداب',
  'تندپز',
  'تندتر',
  'تندخو',
  'تندرا',
  'تندرو',
  'تندری',
  'تندیس',
  'تنزیب',
  'تنزیل',
  'تنسیق',
  'تنشها',
  'تنظیف',
  'تنظیم',
  'تنفسی',
  'تنقیح',
  'تنقیه',
  'تنگاب',
  'تنگدل',
  'تنگنا',
  'تننده',
  'تنه‌ی',
  'تنوره',
  'تنویر',
  'تنیدن',
  'تنیده',
  'تنیزه',
  'تهاتر',
  'تهاجم',
  'تهاون',
  'تهبیه',
  'ته‌چک',
  'تهداب',
  'تهدید',
  'تهذیب',
  'تهران',
  'تهلیل',
  'تهمتن',
  'تهنیت',
  'تهویه',
  'تهیجی',
  'تهیگی',
  'تهییج',
  'توئین',
  'تواتر',
  'توارث',
  'توارد',
  'توازن',
  'تواضع',
  'توافق',
  'توالت',
  'توالد',
  'توالی',
  'توانا',
  'تواند',
  'توانش',
  'توبره',
  'توبه‌',
  'توبیخ',
  'توپاز',
  'توپال',
  'توپچی',
  'توپزن',
  'توپلو',
  'توپها',
  'توپوز',
  'توتون',
  'توتیا',
  'توجهی',
  'توجیه',
  'توحید',
  'تودار',
  'تودور',
  'تودیع',
  'تورات',
  'توربو',
  'تورسک',
  'تورمی',
  'توروف',
  'توریم',
  'توریه',
  'توزیع',
  'توزین',
  'توسعه',
  'توسکا',
  'توسیک',
  'توشیح',
  'توصیف',
  'توصیه',
  'توضیح',
  'توطئه',
  'توفال',
  'توفان',
  'توفیر',
  'توفیق',
  'توقیر',
  'توقیع',
  'توقیف',
  'توکار',
  'توکان',
  'توکیو',
  'توگای',
  'توگرد',
  'توگود',
  'تولدو',
  'تولید',
  'تولیم',
  'توماس',
  'تومور',
  'تونست',
  'تونسی',
  'تونکا',
  'تونکن',
  'تونگا',
  'تونوس',
  'تونیک',
  'تونین',
  'توهمی',
  'توهین',
  'تویتر',
  'تویلت',
  'تیبیا',
  'تیتان',
  'تیراژ',
  'تیرچه',
  'تیررس',
  'تیرکی',
  'تیرگی',
  'تیرها',
  'تیریا',
  'تیزاب',
  'تیزرو',
  'تیزکن',
  'تیزیا',
  'تیفوس',
  'تیلدا',
  'تیماج',
  'تیمار',
  'تیمچه',
  'تیمور',
  'تیموس',
  'تیمول',
  'تیمین',
  'تیولی',
  'تیونر',
  'ثابته',
  'ثابتی',
  'ثالثا',
  'ثالثی',
  'ثانوی',
  'ثانیا',
  'ثانیه',
  'ثروتش',
  'ثعبان',
  'ثلاثه',
  'ثنویت',
  'جابجا',
  'جاثوم',
  'جادار',
  'جاذبه',
  'جارچی',
  'جاروب',
  'جاروک',
  'جاروی',
  'جازده',
  'جاسوس',
  'جاسون',
  'جاکشی',
  'جاگاه',
  'جاگیر',
  'جالبی',
  'جالوت',
  'جالیز',
  'جامدی',
  'جامعه',
  'جانان',
  'جانبه',
  'جانبی',
  'جانما',
  'جانور',
  'جاهای',
  'جاوید',
  'جایزه',
  'جایگه',
  'جبران',
  'جبروت',
  'جبههی',
  'جدائی',
  'جداره',
  'جداری',
  'جدایی',
  'جدولی',
  'جدیدی',
  'جذامی',
  'جراحت',
  'جراحی',
  'جراید',
  'جربزه',
  'جرجیا',
  'جرجیس',
  'جرشفت',
  'جرغند',
  'جرنگی',
  'جریان',
  'جریحه',
  'جریده',
  'جریمه',
  'جزائی',
  'جزایر',
  'جزایی',
  'جزیره',
  'جسارت',
  'جستار',
  'جستجو',
  'جسدها',
  'جسمیت',
  'ج.ش.)',
  'جعفری',
  'جغجغک',
  'جغجغه',
  'جفت‌ه',
  'جگوار',
  'جلادت',
  'جلایی',
  'جلباب',
  'جلبکی',
  'جلسات',
  'جلوتر',
  'جلویی',
  'جلیقه',
  'جلیلی',
  'جلینه',
  'جماعت',
  'جمجمه',
  'جمعیت',
  'جملات',
  'جملگی',
  'جمهور',
  'جنائی',
  'جنابه',
  'جناحی',
  'جنازه',
  'جناغی',
  'جنایت',
  'جنایی',
  'جنبان',
  'جنبشی',
  'جنجال',
  'جندگی',
  'جنسی‌',
  'جنسیت',
  'جنگال',
  'جنگجو',
  'جنگل‌',
  'جنگلی',
  'جنوبی',
  'جنینی',
  'جهادی',
  'جهانی',
  'جهشگر',
  'جهشنه',
  'جهمند',
  'جهنده',
  'جهنم‌',
  'جهنمی',
  'جهیدن',
  'جوابی',
  'جوانا',
  'جوانب',
  'جوانک',
  'جوانه',
  'جوانی',
  'جواهر',
  'جودو۰',
  'جوراب',
  'جوزور',
  'جوسنج',
  'جوشان',
  'جوکی‌',
  'جولاه',
  'جونده',
  'جوهره',
  'جوهری',
  'جویدن',
  'جیببر',
  'جیحون',
  'چابکی',
  'چاپار',
  'چاپچی',
  'چاپگر',
  'چاچول',
  'چاخان',
  'چادری',
  'چاذبه',
  'چارپا',
  'چارتر',
  'چارخو',
  'چارقد',
  'چارکش',
  'چارلز',
  'چارلی',
  'چاشنی',
  'چاقوی',
  'چاکری',
  'چالاک',
  'چپاول',
  'چپگرا',
  'چتروم',
  'چخماق',
  'چخوفی',
  'چراغی',
  'چرانی',
  'چرایی',
  'چرتکه',
  'چرتنه',
  'چرخآب',
  'چرخاب',
  'چرخان',
  'چرخش‌',
  'چرخشی',
  'چرخند',
  'چرخید',
  'چردگی',
  'چرکین',
  'چرمین',
  'چرنده',
  'چریدن',
  'چریکی',
  'چسبان',
  'چسبها',
  'چشایی',
  'چشمان',
  'چشمها',
  'چشنده',
  'چشیدن',
  'چطوری',
  'چغندر',
  'چکامه',
  'چکاوک',
  'چکه‌ء',
  'چکیدن',
  'چکیده',
  'چگالش',
  'چگالی',
  'چگونه',
  'چلانه',
  'چلچله',
  'چلغوز',
  'چلفتی',
  'چلوار',
  'چلیپا',
  'چلیک‌',
  'چلیکی',
  'چماقی',
  'چمبری',
  'چمدان',
  'چموشی',
  'چنبره',
  'چنبری',
  'چندان',
  'چندبر',
  'چندلا',
  'چندین',
  'چنگار',
  'چنگال',
  'چ‌هار',
  'چهارک',
  'چهارم',
  'چوپان',
  'چوروک',
  'چوگان',
  'چولگی',
  'چونکه',
  'چیرگی',
  'چیزبی',
  'چیزرا',
  'چیزها',
  'چیزی‌',
  'چیستی',
  'چینوک',
  'حاجزی',
  'حادثه',
  'حاشیت',
  'حاشیه',
  'حاصله',
  'حافطه',
  'حافظه',
  'حاکمه',
  'حالات',
  'حالتی',
  'حالیا',
  'حامله',
  'حاویه',
  'حبابی',
  'حبقوق',
  'حتماً',
  'حتمیت',
  'حجاری',
  'حجامت',
  'حداقل',
  'حدباغ',
  'حدوسط',
  'حدیثی',
  'حدیده',
  'حدیقه',
  'حذاقت',
  'حراثت',
  'حراجی',
  'حرارت',
  'حراست',
  'حرامی',
  'حرف‌T',
  'حرف‌w',
  'حرفها',
  'حرقفی',
  'حرکات',
  'حرکتی',
  'حرمان',
  'حرمتی',
  'حروفی',
  'حریره',
  'حسابی',
  'حسادت',
  'حساسه',
  'حسگیر',
  'حسهای',
  'حشرات',
  'حشیشه',
  'حصانت',
  'حصیری',
  'حضانت',
  'حضرتی',
  'حفاری',
  'حفاظت',
  'حفاظی',
  'حفظ‌ی',
  'حقارت',
  'حقایق',
  'حقدار',
  'حقوقی',
  'حقیقت',
  'حقیقی',
  'حکاکی',
  'حکایت',
  'حکماً',
  'حکمیت',
  'حکومت',
  'حلاجی',
  'حلاوت',
  'حلزون',
  'حلقوم',
  'حلقوی',
  'حماسه',
  'حماسی',
  'حماقت',
  'حمامه',
  'حمایت',
  'حمایل',
  'حملات',
  'حمله‌',
  'حموضت',
  'حنایی',
  'حنجره',
  'حوادث',
  'حواری',
  'حواشی',
  'حواله',
  'حوالی',
  'حوصله',
  'حوضچه',
  'حیاتی',
  'حیاط‌',
  'حیثیت',
  'حیران',
  'حیوان',
  'خاتمه',
  'خاتون',
  'خاخام',
  'خادمه',
  'خارای',
  'خاربن',
  'خارجه',
  'خارجی',
  'خارخر',
  'خارسر',
  'خارش‌',
  'خازنی',
  'خاشاک',
  'خاصره',
  'خاصیت',
  'خاط‌ر',
  'خاطره',
  'خاکجا',
  'خالص‌',
  'خالصه',
  'خامسا',
  'خاموت',
  'خاموش',
  'خانگی',
  'خانه‌',
  'خاوری',
  'خایگی',
  'خباثت',
  'خبازی',
  'خبرکش',
  'خبرگی',
  'ختایی',
  'خجالت',
  'خجسته',
  'خداست',
  'خدایا',
  'خدایی',
  'خدمات',
  'خدمتی',
  'خرابه',
  'خرابی',
  'خرافی',
  'خرامش',
  'خربزه',
  'خرجین',
  'خرچنگ',
  'خرخره',
  'خردتر',
  'خردسر',
  'خرسند',
  'خرطوم',
  'خرغوس',
  'خرفتی',
  'خرگاه',
  'خرگوش',
  'خرمای',
  'خرمگس',
  'خرناس',
  'خرنوب',
  'خروار',
  'خروپف',
  'خروج‌',
  'خروجی',
  'خروسک',
  'خروشد',
  'خریدن',
  'خریره',
  'خزانه',
  'خزپوش',
  'خزدار',
  'خزنده',
  'خزه‌ء',
  'خزیدن',
  'خزیده',
  'خزینه',
  'خسارت',
  'خستگی',
  'خستوش',
  'خس‌خس',
  'خسران',
  'خسیاز',
  'خسیسی',
  'خشخاش',
  'خشنود',
  'خشونت',
  'خصائص',
  'خصاصی',
  'خصوصی',
  'خصومت',
  'خصیصه',
  'خطابه',
  'خطابی',
  'خطاها',
  'خطایی',
  'خطدار',
  'خطرات',
  'خطسیر',
  'خط‌کش',
  'خط‌می',
  'خط‌ور',
  'خط‌وط',
  'خطیئه',
  'خط‌یب',
  'خط‌یر',
  'خفچه‌',
  'خفقان',
  'خلاصه',
  'خلاصی',
  'خلافت',
  'خلاقه',
  'خلبان',
  'خلخال',
  'خلط‌ی',
  'خلقتی',
  'خلماش',
  'خلنگ‌',
  'خلیدن',
  'خلیفه',
  'خماری',
  'خمسین',
  'خموشی',
  'خمیدن',
  'خمیده',
  'خمیره',
  'خمیری',
  'خمینی',
  'خنجری',
  'خندان',
  'خنزیر',
  'خنیده',
  'خواب‌',
  'خوابش',
  'خوابه',
  'خوابی',
  'خواجه',
  'خواری',
  'خواست',
  'خوانا',
  'خوانش',
  'خوانی',
  'خواهد',
  'خواهر',
  'خواهش',
  'خواهی',
  'خوبتر',
  'خوبرو',
  'خودان',
  'خودرا',
  'خودرو',
  'خودزا',
  'خودسر',
  'خودوس',
  'خوراک',
  'خورخه',
  'خوردن',
  'خورده',
  'خوردی',
  'خورشت',
  'خورند',
  'خوری‌',
  'خوشاب',
  'خوشبو',
  'خوشخو',
  'خوشدل',
  'خوشرو',
  'خوشکل',
  'خوشگل',
  'خولان',
  'خوناب',
  'خونی‌',
  'خونین',
  'خویشی',
  'خیارک',
  'خیاره',
  'خیاری',
  'خیاطه',
  'خیاطی',
  'خیالی',
  'خیانت',
  'خیرات',
  'خیرگی',
  'خیریه',
  'خیزآب',
  'خیزاب',
  'خیزند',
  'خیشوم',
  'خیلون',
  'خیمار',
  'دائره',
  'دائما',
  'دائمی',
  'داتیو',
  'داخله',
  'داخلی',
  'دادار',
  'داداش',
  'دادرس',
  'دادزن',
  'دادگر',
  'دادگی',
  'دادن‌',
  'دادند',
  'دادنی',
  'دادور',
  'دادیا',
  'دادیک',
  'داراف',
  'داران',
  'دارای',
  'داربر',
  'دارپس',
  'داردو',
  'دارزی',
  'دارکه',
  'دارند',
  'داروش',
  'داروی',
  'داری‌',
  'داریا',
  'دارید',
  'دارین',
  'داسیت',
  'داشتن',
  'داشته',
  'داعیه',
  'داغان',
  'دافعه',
  'دافنه',
  'دافنی',
  'داکار',
  'داکسی',
  'دالان',
  'دالون',
  'داماد',
  'دامان',
  'دامپر',
  'دامنه',
  'دامنی',
  'دامها',
  'دانان',
  'دانته',
  'دانگل',
  'دانه‌',
  'دانوب',
  'دانیل',
  'داودی',
  'داوری',
  'داوود',
  'داوید',
  'دایره',
  'دایگی',
  'دایمی',
  'دباغی',
  'دبدبه',
  'دبیره',
  'دبیری',
  'دبیزه',
  'دچاری',
  'دخالت',
  'دختان',
  'دخترک',
  'دختری',
  'ددباغ',
  'ددمنش',
  'درآمد',
  'دراثر',
  'درازا',
  'درازی',
  'دراژه',
  'دراصل',
  'دراکم',
  'دراکو',
  'درامد',
  'درایت',
  'دراید',
  'درایو',
  'درایی',
  'درباب',
  'دربار',
  'دربان',
  'دربدر',
  'دربست',
  'دربند',
  'درپشت',
  'درپوش',
  'درپیش',
  'درتیر',
  'درجای',
  'درجفت',
  'درجلو',
  'درجهت',
  'درچاپ',
  'درحال',
  'درخام',
  'درختی',
  'درخشش',
  'درخفا',
  'درخور',
  'درخون',
  'درددل',
  'دردست',
  'دردسر',
  'درزدن',
  'درزیر',
  'درستی',
  'درسطح',
  'درسوی',
  'درشتی',
  'درشرف',
  'درشکن',
  'درشکه',
  'درشگه',
  'درشهر',
  'درضمن',
  'درعقب',
  'درعلم',
  'درعوض',
  'درعین',
  'درفشی',
  'درفصل',
  'درقرن',
  'درقفا',
  'درکار',
  'درکسب',
  'درگاه',
  'درگیر',
  'درمال',
  'درمان',
  'درمتن',
  'درمحل',
  'درمرگ',
  'درمشت',
  'درمنه',
  'درنده',
  'درنصب',
  'درنفس',
  'درنوک',
  'درهای',
  'درهمه',
  'درهمی',
  'درهند',
  'دروار',
  'درودن',
  'درودی',
  'دروسط',
  'دروغی',
  'دروگر',
  'درونه',
  'درونی',
  'درویش',
  'دریای',
  'دریبل',
  'دریچه',
  'دریدن',
  'دریغا',
  'دزدان',
  'دزدکی',
  'دزدها',
  'دژبان',
  'دژپیه',
  'دژخیم',
  'دژدیس',
  'دژکوب',
  'دستار',
  'دستاس',
  'دستان',
  'دستخط',
  'دسترس',
  'دستکش',
  'دستکم',
  'دستها',
  'دستور',
  'دسیسه',
  'دشپیل',
  'دشتان',
  'دشمن‌',
  'دشمنی',
  'دشنام',
  'دشوار',
  'دعاوی',
  'دعایی',
  'دعوای',
  'دعوتی',
  'دغدغک',
  'دغدغه',
  'دفاتر',
  'دفاعی',
  'دفتری',
  'دفران',
  'دفعات',
  'دفعهً',
  'دفینه',
  'دقیقا',
  'دقیقه',
  'دکارت',
  'دکترا',
  'دکتری',
  'دکلمه',
  'دکوپگ',
  'د.گ.)',
  'دگرزا',
  'دلاری',
  'دلاکی',
  'دلالت',
  'دلاله',
  'دلانه',
  'دلاور',
  'دلایل',
  'دلبری',
  'دلتنگ',
  'دلچسب',
  'دلخور',
  'دلخوش',
  'دلدار',
  'دلربا',
  'دلزده',
  'دلسرد',
  'دلسوز',
  'دلشاد',
  'دلشده',
  'دلفین',
  'دلگرم',
  'دلگیر',
  'دلهره',
  'دلیجه',
  'دلیری',
  'دلیله',
  'دلیلی',
  'دمادم',
  'دماغک',
  'دماغه',
  'دماغی',
  'دمامه',
  'دمایی',
  'دمبرگ',
  'دمدمی',
  'دمزنی',
  'دمساز',
  'دمشقی',
  'دمگاه',
  'دمنده',
  'دمیدن',
  'دمیده',
  'دنائت',
  'دنبال',
  'دنبلا',
  'دندان',
  'دنیای',
  'دنیپر',
  'دنیوی',
  'دهاتی',
  'دهانک',
  'دهانه',
  'دهانی',
  'دهبان',
  'دهدار',
  'دهدهی',
  'دهدیخ',
  'ده‌رو',
  'دهقان',
  'دهکده',
  'دهلیز',
  'دهمین',
  'دهناد',
  'دهنده',
  'دهوند',
  'ده‌یک',
  'دواتم',
  'دوالی',
  'دوامی',
  'دوانی',
  'دواهن',
  'دوایر',
  'دوبار',
  'دوبال',
  'دوبرا',
  'دوبله',
  'دوبند',
  'دوبین',
  'دوپار',
  'دوپای',
  'دوپنی',
  'دوپها',
  'دوتخم',
  'دوتکه',
  'دوتیر',
  'دوجنس',
  'دوجین',
  'دوحرف',
  'دوخال',
  'دوخان',
  'دوختن',
  'دوخته',
  'دودست',
  'دودکش',
  'دودلی',
  'دودمه',
  'دوراز',
  'دورال',
  'دوران',
  'دوربر',
  'دورتر',
  'دورسی',
  'دورشو',
  'دورکن',
  'دورگه',
  'دورگو',
  'دورنگ',
  'دورهء',
  'دورها',
  'دوروم',
  'دوریس',
  'دورین',
  'دوزخ‌',
  'دوزخی',
  'دوستش',
  'دوستی',
  'دوسره',
  'دوسوی',
  'دوسیه',
  'دوشاب',
  'دوشاخ',
  'دوشین',
  'دوطرف',
  'دوغاب',
  'دوقاب',
  'دوقلو',
  'دوکپه',
  'دوکور',
  'دولاب',
  'دولبه',
  'دولپه',
  'دولتی',
  'دولول',
  'دوماه',
  'دومین',
  'دونده',
  'دونیم',
  'دویدن',
  'دویده',
  'دویست',
  'دیابت',
  'دیازو',
  'دیانت',
  'دیپلم',
  'دیجور',
  'دیدار',
  'دیدنی',
  'دیرپا',
  'دیرتر',
  'دیررس',
  'دیرند',
  'دیرنه',
  'دیرین',
  'دیزلی',
  'دیزنی',
  'دیسکو',
  'دیسکی',
  'دیکته',
  'دیکنز',
  'دیگچه',
  'دیگرش',
  'دیگری',
  'دیلار',
  'دینار',
  'دینام',
  'دینان',
  'دینوی',
  'دیهیم',
  'دیواد',
  'دیوار',
  'دیوان',
  'دیویس',
  'ذائقه',
  'ذاتیت',
  'ذخایر',
  'ذخیره',
  'ذربین',
  'ذرهای',
  'ذره‌ی',
  'ذغالی',
  'ذکاوت',
  'ذهنیت',
  'ذیحجه',
  'ذیروح',
  'ذینفع',
  'رئیسه',
  'رئیسی',
  'رابرت',
  'رابطه',
  'رابطی',
  'رابیک',
  'رابین',
  'راتبه',
  'راترک',
  'راجعه',
  'راحت‌',
  'راحتی',
  'راخفه',
  'رادار',
  'راداس',
  'رادون',
  'رادیو',
  'رازقی',
  'راستا',
  'راسته',
  'راستی',
  'راسوی',
  'رافضی',
  'راکت‌',
  'راکتی',
  'راکون',
  'راگبی',
  'راگون',
  'راگوی',
  'رامیت',
  'راندن',
  'رانده',
  'رانشت',
  'رانکا',
  'رانکی',
  'راهاپ',
  'راهبر',
  'راهبه',
  'راهبی',
  'راهرو',
  'راهزن',
  'راوید',
  'رایحه',
  'رایزن',
  'ربائی',
  'رباعی',
  'ربانی',
  'ربایش',
  'ربایی',
  'ربط‌ی',
  'ربودن',
  'ربیبه',
  'ربیعی',
  'رتبهء',
  'رجحان',
  'رجوعی',
  'رحمدل',
  'رخداد',
  'رخسار',
  'رخشان',
  'ردائت',
  'ردپای',
  'ردشدن',
  'رده‌ی',
  'ردوکس',
  'ردوود',
  'ردیاب',
  'ردیفی',
  'رذالت',
  'رزانه',
  'رزینی',
  'رژیمی',
  'رساگر',
  'رسالت',
  'رساله',
  'رسان‌',
  'رسانا',
  'رساند',
  'رسانش',
  'رسانه',
  'رسانی',
  'رسایی',
  'رستاق',
  'رستنی',
  'رسما"',
  'رسمیت',
  'رسنده',
  'رسوبی',
  'رسیدن',
  'رسیده',
  'رشادت',
  'رشدوک',
  'رصحنه',
  'رضاعی',
  'رضایت',
  'رضوان',
  'رط‌ل‌',
  'رط‌لی',
  'رطوبت',
  'رعالم',
  'رعایا',
  'رعایت',
  'رعیتی',
  'رفاقت',
  'رفاهی',
  'رفتار',
  'رفتگر',
  'رفتگی',
  'رفتن‌',
  'رفتنی',
  'رفلکس',
  'رفوزه',
  'رفوگر',
  'رفیقه',
  'رقابت',
  'رقاصه',
  'رقاصی',
  'رقصگر',
  'رقیق‌',
  'رقیقی',
  'رقیمه',
  'رکابی',
  'رکتال',
  'رکتوس',
  'رک‌گو',
  'رکورد',
  'رگبار',
  'رگبند',
  'رگبی‌',
  'رگزنی',
  'رگ‌ها',
  'رگهای',
  'رمایه',
  'رمزها',
  'رمیدن',
  'رمیده',
  'رنجبر',
  'رنجور',
  'رنگبر',
  'رنگرز',
  'رنگها',
  'رنگ‌ی',
  'رنگین',
  'رهائی',
  'رهایی',
  'رهبری',
  'رهگذر',
  'رهگیر',
  'رهنما',
  'رهوار',
  'رهیدن',
  'روابط',
  'رواقی',
  'روالی',
  'روانه',
  'روانی',
  'روایت',
  'روایی',
  'روباز',
  'روبان',
  'روباه',
  'روبرو',
  'روبند',
  'روبوت',
  'روپاک',
  'روپوش',
  'روپیه',
  'روتور',
  'روتیل',
  'روحیه',
  'روخاک',
  'رودان',
  'روده‌',
  'رودهء',
  'رودیم',
  'روروک',
  'روریز',
  'روزاز',
  'روزده',
  'روزنه',
  'روزهء',
  'روزهآ',
  'روسای',
  'روسپی',
  'روستا',
  'روسری',
  'روسی‌',
  'روسیه',
  'روشن‌',
  'روشنی',
  'روطاق',
  'روغنی',
  'روفتن',
  'روفته',
  'روکش‌',
  'روکشی',
  'روکلا',
  'روگذر',
  'رولور',
  'رومال',
  'رومیا',
  'روناس',
  'رونده',
  'روندو',
  'رونیک',
  'رویال',
  'رویان',
  'رویای',
  'رویشی',
  'رویهم',
  'رویین',
  'ریاست',
  'ریاضت',
  'ریاضی',
  'ریالی',
  'ریایی',
  'ریبوز',
  'ریحان',
  'ریختن',
  'ریخته',
  'ریدلی',
  'ریزان',
  'ریزبر',
  'ریزند',
  'ریسین',
  'ریشته',
  'ریشهء',
  'ریکشا',
  'ریمنی',
  'رینگر',
  'رینگی',
  'رینیت',
  'ریواس',
  'زئیری',
  'زائده',
  'زاپاس',
  'زادئی',
  'زادان',
  'زادگی',
  'زاستن',
  'زاغچه',
  'زاگرب',
  'زامبی',
  'زامیه',
  'زانوی',
  'زانیه',
  'زاوری',
  'زاوش‌',
  'زاویه',
  'زایده',
  'زایشی',
  'زایلن',
  'زباله',
  'زبانه',
  'زبانی',
  'زبرجد',
  'زبرین',
  'زبونی',
  'زپرتی',
  'زجاجی',
  'زخمی‌',
  'زدایا',
  'زدایش',
  'زدایی',
  'زده‌ء',
  'زدهام',
  'زدودن',
  'زدوده',
  'زراعت',
  'زراعی',
  'زرافه',
  'زربفت',
  'زربین',
  'زرتشت',
  'زرخیز',
  'زردآب',
  'زرداب',
  'زردار',
  'زردپی',
  'زرسنج',
  'زرشکی',
  'زرفین',
  'زرگری',
  'زرگیل',
  'زرنگی',
  'زرنما',
  'زرنیخ',
  'زرهی.',
  'زرورق',
  'زرینی',
  'ز.ش.)',
  'ز.ع.-',
  'زعارت',
  'زعامت',
  'زغالی',
  'زکامی',
  'زلالی',
  'زلاند',
  'زلزله',
  'زمانه',
  'زمانی',
  'زمختی',
  'زمزمه',
  'زمونه',
  'زمین‌',
  'زمینه',
  'زمینی',
  'زنان‌',
  'زنانه',
  'زنبور',
  'زنبیل',
  'زنجره',
  'زنجیر',
  'زندان',
  'زندقه',
  'زندگی',
  'زندیق',
  'زندیک',
  'زنگار',
  'زنگام',
  'زننده',
  'زن‌ها',
  'زنهار',
  'زهاری',
  'زهدان',
  'زهروی',
  'زهرین',
  'زه‌کش',
  'زهکشی',
  'زوائد',
  'زوایا',
  'زواید',
  'زوبین',
  'زوجات',
  'زوجین',
  'زودپز',
  'زودتر',
  'زودرس',
  'زورق‌',
  'زورقی',
  'زورکی',
  'زورگو',
  'زوریخ',
  'زوستر',
  'زوفای',
  'زویبک',
  'زیاچه',
  'زیاده',
  'زیادی',
  'زیارت',
  'زیبای',
  'زیتون',
  'زیرکل',
  'زیرکی',
  'زیرین',
  'زیستن',
  'زیستی',
  'زیمنس',
  'زینتی',
  'ژاپنی',
  'ژاپون',
  'ژادین',
  'ژتونی',
  'ژرفای',
  'ژرفنا',
  'ژرمنی',
  'ژژنوم',
  'ژکیدن',
  'ژنتیک',
  'ژنریک',
  'ژنهای',
  'ژوئیه',
  'ژونال',
  'سئوال',
  'سائله',
  'ساباط',
  'سابقا',
  'سابقه',
  'سابقی',
  'سابین',
  'ساتیر',
  'ساتین',
  'ساچمه',
  'ساحره',
  'ساحری',
  'ساحلی',
  'ساخت‌',
  'ساختن',
  'ساخته',
  'ساخلو',
  'سادگی',
  'ساده‌',
  'ساران',
  'سارنگ',
  'ساروج',
  'سارین',
  'سازان',
  'ساززن',
  'سازکه',
  'سازند',
  'ساسات',
  'ساط‌ع',
  'ساطور',
  'ساعات',
  'ساعت‌',
  'ساعته',
  'ساعتی',
  'ساکشن',
  'ساکول',
  'سالاد',
  'سالار',
  'سالبه',
  'سالسا',
  'سالگی',
  'سالوس',
  'سالون',
  'سامان',
  'سامبا',
  'سامری',
  'سامعه',
  'ساموا',
  'سانان',
  'سانتو',
  'سانتی',
  'سانحه',
  'ساندر',
  'سایاگ',
  'سایبر',
  'سایتی',
  'سایشی',
  'سایه‌',
  'سبابه',
  'سباحت',
  'سباسه',
  'سبحاف',
  'سبعیت',
  'سبکتر',
  'سبکسر',
  'سبکیک',
  'سبوره',
  'سبوسه',
  'سپارش',
  'سپاری',
  'سپاهی',
  'سپتوم',
  'سپتیک',
  'سپردن',
  'سپرده',
  'سپرزی',
  'سپهبد',
  'سپهری',
  'سپوری',
  'سپیده',
  'ستادی',
  'ستارک',
  'ستاره',
  'ستایش',
  'ستبرا',
  'ستبری',
  'ستردن',
  'سترده',
  'سترسا',
  'سترون',
  'ستمگر',
  'سته‌ی',
  'ستوان',
  'ستودن',
  'ستوده',
  'ستوسه',
  'ستونک',
  'ستونی',
  'ستوهی',
  'ستیزه',
  'ستیزی',
  'ستیکی',
  'سجاده',
  'سجیدن',
  'سحابی',
  'سخاوت',
  'سخریه',
  'سخنان',
  'سخنگو',
  'سخنور',
  'سرآمد',
  'سرئوس',
  'سراجه',
  'سراجی',
  'سراچه',
  'سراسب',
  'سراسپ',
  'سراسر',
  'سرانه',
  'سرایت',
  'سرایش',
  'سرایی',
  'سربار',
  'سرباز',
  'سربری',
  'سربسر',
  'سربنا',
  'سربند',
  'سرپهن',
  'سرپوش',
  'سرپیچ',
  'سرتخت',
  'سرتره',
  'سرتیپ',
  'سرتیز',
  'سرجمع',
  'سرچپق',
  'سرچکش',
  'سرحال',
  'سرحدی',
  'سرخاب',
  'سرخجه',
  'سرخچه',
  'سرخرگ',
  'سرخس‌',
  'سرخک‌',
  'سرخود',
  'سرخوش',
  'سرداب',
  'سردار',
  'سردخو',
  'سردرب',
  'سردرد',
  'سردست',
  'سردکل',
  'سردکن',
  'سردمی',
  'سَرده',
  'سرریز',
  'سرزدن',
  'سرزده',
  'سرزنش',
  'سرزین',
  'سرساز',
  'سرسام',
  'سرسبز',
  'سرسخت',
  'سرسرا',
  'سرسره',
  'سرسری',
  'سرشاخ',
  'سرشار',
  'سرشان',
  'سرشتن',
  'سرشته',
  'سرشتی',
  'سرشکن',
  'سرشیر',
  'سرضرب',
  'سرطاق',
  'سرطان',
  'سرغذا',
  'سرکجی',
  'سرکسی',
  'سرکشی',
  'سرکوب',
  'سرکوه',
  'سرگرد',
  'سرگرم',
  'سرگشا',
  'سرگین',
  'سرلام',
  'سرمای',
  'سرمست',
  'سرمشق',
  'سرنام',
  'سرهنگ',
  'سروال',
  'سروان',
  'سروته',
  'سرودن',
  'سروده',
  'سرودی',
  'سروری',
  'سروزی',
  'سروشی',
  'سرویس',
  'سریال',
  'سریدن',
  'سریشم',
  'سریع‌',
  'سریعا',
  'سریعی',
  'سرینی',
  'سزایی',
  'سسفید',
  'سس‌ها',
  'سشوار',
  'سط‌ح‌',
  'سطحاب',
  'سط‌حی',
  'سط‌ری',
  'سط‌وح',
  'سط‌ور',
  'سعادت',
  'سعایت',
  'سعودی',
  'سفارت',
  'سفارش',
  'سفالی',
  'سفاهت',
  'سفرجل',
  'سفرنگ',
  'سفسطه',
  'سفلای',
  'سفلگی',
  'سفلی‌',
  'سفلیس',
  'سفیدک',
  'سفیده',
  'سفیدی',
  'سفینه',
  'سقراط',
  'سقنقر',
  'سقوطی',
  'سکانی',
  'سکایی',
  'سکجور',
  'سکسکه',
  'سکهای',
  'سکه‌ی',
  'سکوبی',
  'سکونت',
  'سگالش',
  'سگدست',
  'سگ‌ها',
  'سلاح‌',
  'سلاخی',
  'سلاست',
  'سلاله',
  'سلامت',
  'سلسله',
  'سلطان',
  'سلطنت',
  'سلط‌ه',
  'سلفیه',
  'سلمان',
  'سلمبه',
  'سل‌ها',
  'سلول‌',
  'سلولز',
  'سلولی',
  'سلیاک',
  'سلیقه',
  'سماجت',
  'سماکی',
  'سماور',
  'سماوی',
  'سمپاش',
  'سمنتی',
  'سمندر',
  'سمنوی',
  'سمیره',
  'سنایش',
  'سنبله',
  'سنتور',
  'سنتی‌',
  'سنجاب',
  'سنجاق',
  'سنجشی',
  'سنجگر',
  'سنخیت',
  'سندان',
  'سندرز',
  'سندرم',
  'سندیت',
  'سنکوپ',
  'سنگاب',
  'سنگال',
  'سنگبر',
  'سنگدل',
  'سنگسر',
  'سنگها',
  'سنگین',
  'سهیلا',
  'سنوری',
  'سهامی',
  'سهروز',
  'سهزنی',
  'سُهِش',
  'سه‌لا',
  'سهموی',
  'سهمیه',
  'سهولت',
  'سوءظن',
  'سوئدی',
  'سوئیچ',
  'سوئیس',
  'سوابق',
  'سواحل',
  'سواره',
  'سواری',
  'سوپاپ',
  'سوختن',
  'سوخته',
  'سوختی',
  'سودان',
  'سودتر',
  'سودجو',
  'سوراخ',
  'سورچی',
  'سوریه',
  'سوزاک',
  'سوزان',
  'سوزنک',
  'سوزنی',
  'سوسنی',
  'سوسیس',
  'سوسیگ',
  'سوغات',
  'سوگلی',
  'سوگند',
  'سولفا',
  'سولفش',
  'سومین',
  'سونات',
  'سونار',
  'سوهان',
  'سویچی',
  'سویسی',
  'سویفت',
  'سوینگ',
  'سیاتل',
  'سیاحت',
  'سیادت',
  'سیارک',
  'سیاره',
  'سیاست',
  'سیاسی',
  'سیاله',
  'سیامی',
  'سیانو',
  'سیاهه',
  'سیاهی',
  'سیبری',
  'سیبیا',
  'سیتزه',
  'سیتمی',
  'سیدنی',
  'سیراب',
  'سیروز',
  'سیروس',
  'سیزاب',
  'سیزده',
  'سیستم',
  'سیسرو',
  'سیسکو',
  'سیسیل',
  'سیطره',
  'سیفون',
  'سیکلت',
  'سیگار',
  'سیگما',
  'سیلاب',
  'سیلان',
  'سیلبر',
  'سیلیت',
  'سیلیس',
  'سیلین',
  'سیماب',
  'سیمان',
  'سیمای',
  'سیمبر',
  'سیمرغ',
  'سیمگر',
  'سیمهء',
  'سیمی‌',
  'سیمین',
  'سینما',
  'سینوس',
  'شائول',
  'شاباش',
  'شاتون',
  'شاخچه',
  'شاخصه',
  'شاخگی',
  'شاداب',
  'شادگر',
  'شارژی',
  'شاعرک',
  'شاعره',
  'شاعری',
  'شاقول',
  'شاکلی',
  'شاگرد',
  'شاگرو',
  'شالده',
  'شانسی',
  'شانکر',
  'شانه‌',
  'شانون',
  'شاهپر',
  'شاهرخ',
  'شاهرگ',
  'شاهین',
  'شایان',
  'شایبه',
  'شایست',
  'شایعه',
  'شبانه',
  'شبانی',
  'شباهت',
  'شبخیز',
  'شبدری',
  'شبکیه',
  'شبگرد',
  'شپشوی',
  'شترنگ',
  'شجاعت',
  'شخانه',
  'شخصیت',
  'شدگان',
  'شدگی‌',
  'شده‌ی',
  'شدیدا',
  'شرابه',
  'شرابی',
  'شرارت',
  'شراره',
  'شرافت',
  'شراکت',
  'شرایط',
  'شرایط',
  'شربتی',
  'شرپنل',
  'شرطلب',
  'شرط‌ی',
  'شرعاً',
  'شرقی‌',
  'شرکت‌',
  'شرکتی',
  'شرکسی',
  'شریان',
  'شریعت',
  'شریکی',
  'شستشو',
  'شستنی',
  'ششمین',
  'شطرنج',
  'شعائر',
  'شعاعی',
  'شعبات',
  'شعبان',
  'شعبده',
  'شعرای',
  'شعریه',
  'شفاعت',
  'شفافی',
  'شفاها',
  'شفاهی',
  'شفتین',
  'شفیره',
  'شفیعه',
  'شقاقل',
  'شقایق',
  'شقیقه',
  'شکاری',
  'شکافت',
  'شکافه',
  'شکافی',
  'شکاکی',
  'شکایت',
  'شکرین',
  'شکستن',
  'شکسته',
  'شکفتن',
  'شکلات',
  'شکمبه',
  'شکنبه',
  'شکنجه',
  'شکوفا',
  'شکوفه',
  'شکیبا',
  'شکیدن',
  'شگفتا',
  'شگفتن',
  'شگفتی',
  'شلجمی',
  'شلخته',
  'شلوار',
  'شلوغی',
  'شلیته',
  'شلیدن',
  'شماتت',
  'شمارش',
  'شماره',
  'شماری',
  'شمال‌',
  'شمالا',
  'شماله',
  'شمالی',
  'شمایل',
  'شمخال',
  'شمردن',
  'شمرده',
  'شمشاد',
  'شمشار',
  'شمشیر',
  'شناخت',
  'شناسد',
  'شناسه',
  'شناسی',
  'شناعت',
  'شناگر',
  'شناور',
  'شنایی',
  'شنجرف',
  'شندار',
  'شن‌زی',
  'شنگار',
  'شنگاه',
  'شنگرف',
  'شنگول',
  'شنودی',
  'شنیدن',
  'شنیده',
  'شهابی',
  'شهادت',
  'شهامت',
  'شهباز',
  'شهرها',
  'شهروا',
  'شهریه',
  'شهمات',
  'شهوتی',
  'شواهد',
  'شوایی',
  'شوخگن',
  'شوراب',
  'شورای',
  'شوربا',
  'شورشی',
  'شورگن',
  'شوروی',
  'شوگان',
  'شولات',
  'شونده',
  'شوهری',
  'شیادی',
  'شیاری',
  'شیپور',
  'شیرجه',
  'شیردل',
  'شیرده',
  'شیرزن',
  'شیرکم',
  'شیری‌',
  'شیریخ',
  'شیرین',
  'شیطان',
  'شیطنت',
  'شیفتن',
  'شیفته',
  'شیگلا',
  'شیلات',
  'شیلان',
  'شیلنگ',
  'شیلیت',
  'شینتو',
  'صابون',
  'صادره',
  'صاریغ',
  'صاعقه',
  'صاغری',
  'صباحت',
  'صباره',
  'صباوت',
  'صبحدم',
  'صحابه',
  'صحافی',
  'صحبتی',
  'صحیفه',
  'صدارت',
  'صدارس',
  'صداقت',
  'صدایی',
  'صدقات',
  'صراحت',
  'صراحی',
  'صرافت',
  'صرافی',
  'صربیا',
  'صریحا',
  'صعوبت',
  'صعودی',
  'صغرای',
  'صغیره',
  'صفاقی',
  'صفحات',
  'صفریا',
  'صفنیا',
  'صفیری',
  'صلابت',
  'صلابه',
  'صلبیه',
  'صلحجو',
  'صلحیه',
  'صلیبی',
  'صمیمی',
  'صناعت',
  'صنایع',
  'صندلی',
  'صندوق',
  'صنعتی',
  'صنوبر',
  'صهیون',
  'صوتی‌',
  'صورت‌',
  'صورتی',
  'صوفیه',
  'صومعه',
  'صیادی',
  'صیانت',
  'صیرفی',
  'صیغهء',
  'صیقلی',
  'ضابطه',
  'ضامنی',
  'ضخامت',
  'ضدخزه',
  'ضددرد',
  'ضدصدا',
  'ضد‌یخ',
  'ضربات',
  'ضربان',
  'ضربت‌',
  'ضربدر',
  'ضربهی',
  'ضرغام',
  'ضرورت',
  'ضروری',
  'ضلالت',
  'ضمانت',
  'ضمیری',
  'ضمیمه',
  'ضوابط',
  'ضیافت',
  'طارمی',
  'طاعون',
  'طاقچه',
  'طالبی',
  'طاهره',
  'طاووس',
  'طایفه',
  'طبابت',
  'طباخی',
  'ط‌بال',
  'طبعا"',
  'ط‌بعی',
  'طبقات',
  'ط‌بقه',
  'طبقهء',
  'ط‌بله',
  'ط‌بیب',
  'طبیعت',
  'طبیعی',
  'ط‌حال',
  'طحالی',
  'ط‌راح',
  'طراحی',
  'ط‌رار',
  'طراری',
  'طراوت',
  'ط‌رفه',
  'ط‌رفی',
  'طرفین',
  'طرهای',
  'ط‌ریق',
  'طریقت',
  'طریقه',
  'طریقی',
  'ط‌عام',
  'ط‌عمه',
  'ط‌عنه',
  'طغیان',
  'ط‌فره',
  'طفیلی',
  'طلائی',
  'ط‌لاق',
  'طلاقت',
  'ط‌لای',
  'طلایه',
  'طلایی',
  'ط‌لبی',
  'ط‌لسم',
  'ط‌لوع',
  'طلیعه',
  'ط‌ماع',
  'ط‌ناب',
  'طنابی',
  'ط‌نین',
  'طنینی',
  'طهارت',
  'ط‌واف',
  'طوایف',
  'طوط‌ی',
  'طوفان',
  'طوقچه',
  'ط‌وقی',
  'طولا"',
  'ط‌ولک',
  'طولها',
  'ط‌ولی',
  'طومار',
  'ط‌ویل',
  'طویله',
  'طویلی',
  'ط‌یار',
  'طیاره',
  'طیران',
  'ظاهرا',
  'ظاهری',
  'ظرافت',
  'ظرفیت',
  'ظ‌روف',
  'ظروفی',
  'ظ‌ریف',
  'ظ‌هری',
  'ظ‌هور',
  'ظواهر',
  'عائله',
  'عادات',
  'عادتا',
  'عارضه',
  'عارضی',
  'عاریه',
  'عاشقی',
  'عاطفه',
  'عاطفی',
  'عاط‌ل',
  'عافیت',
  'عاقبت',
  'عاقله',
  'عالیه',
  'عامدا',
  'عامل‌',
  'عامله',
  'عاملی',
  'عایدی',
  'عبادت',
  'عبارت',
  'عباسی',
  'عبرتی',
  'عبوسی',
  'عتیقه',
  'عجالت',
  'عجانی',
  'عجوزه',
  'عدالت',
  'عداوت',
  'عدلیه',
  'عدهای',
  'عدیده',
  'عذوبت',
  'عرابه',
  'عراقی',
  'عربده',
  'عرفان',
  'عرفای',
  'عرفیت',
  'عرقزا',
  'عروسک',
  'عروسی',
  'عروضی',
  'عروقی',
  'عریان',
  'عریضه',
  'عزارا',
  'عزایم',
  'عزیزم',
  'عزیزی',
  'عزیمت',
  'عشریه',
  'عشیره',
  'عصابه',
  'عصاره',
  'عصایی',
  'عصبیت',
  'عصریخ',
  'عصمت‌',
  'عصیان',
  'عضلات',
  'عضله‌',
  'عضویت',
  'عطارد',
  'عط‌ری',
  'عط‌سه',
  'عط‌فی',
  'عطوفت',
  'عظ‌مت',
  'عظ‌می',
  'عظ‌یم',
  'عظیمه',
  'عظیمی',
  'عفریت',
  'عفونت',
  'عفونی',
  'عقابی',
  'عقاید',
  'عقربک',
  'عقربه',
  'عقوبت',
  'عقیدت',
  'عقیده',
  'عقیمی',
  'عکاسی',
  'عکریش',
  'علائم',
  'علاجی',
  'علاقه',
  'علامت',
  'علامه',
  'علاوه',
  'علایم',
  'علفچر',
  'علمیه',
  'علوفه',
  'علیای',
  'عمارت',
  'عماره',
  'عماری',
  'عمامه',
  'عمانی',
  'عمدا"',
  'عمداً',
  'عمدتا',
  'عمران',
  'عملگر',
  'عملگی',
  'عملی‌',
  'عمودا',
  'عمودی',
  'عموما',
  'عمومی',
  'عمیقا',
  'عناب‌',
  'عنابی',
  'عناصر',
  'عنایت',
  'عنبری',
  'عنبیه',
  'عنوان',
  'عهیار',
  'عوارض',
  'عواطف',
  'عواقب',
  'عوالم',
  'عوامل',
  'عواید',
  'عودزن',
  'عیادت',
  'عیاری',
  'عیاشی',
  'عیبجو',
  'عیسی‌',
  'عینکی',
  'عینیت',
  'غائله',
  'غارزی',
  'غارها',
  'غازیا',
  'غاسول',
  'غاشیه',
  'غالبا',
  'غبط‌ه',
  'غده‌ء',
  'غدهای',
  'غده‌ی',
  'غذائی',
  'غذایی',
  'غرابت',
  'غرامت',
  'غرایز',
  'غربال',
  'غربیل',
  'غرغره',
  'غرغرو',
  'غریبه',
  'غریبی',
  'غریدن',
  'غریزه',
  'غریزی',
  'غشایی',
  'غضروف',
  'غفران',
  'غفلتا',
  'غفلهً',
  'غلامی',
  'غلتان',
  'غلتکی',
  'غلطان',
  'غلط‌ک',
  'غلطکی',
  'غلظ‌ت',
  'غلغلک',
  'غلغله',
  'غلیان',
  'غمامه',
  'غمباد',
  'غمزده',
  'غمگین',
  'غمناک',
  'غنهای',
  'غنودن',
  'غنوده',
  'غنیمت',
  'غواصی',
  'غوایت',
  'غوط‌ه',
  'غیابی',
  'غیبگو',
  'غیره‌',
  'فاتحه',
  'فاجعه',
  'فاحشه',
  'فاخته',
  'فاراد',
  'فارسی',
  'فاستر',
  'فاشحه',
  'فاصله',
  'فاضله',
  'فاعله',
  'فاعلی',
  'فاکنر',
  'فالگو',
  'فالوپ',
  'فالون',
  'فالیز',
  'فالیک',
  'فامیل',
  'فانوس',
  'فایده',
  'فایلی',
  'فتوسل',
  'فتیله',
  'فحاشی',
  'فحشاء',
  'فحلیت',
  'فدایی',
  'فدرال',
  'فراتر',
  'فراخی',
  'فراری',
  'فرازا',
  'فرازش',
  'فرازی',
  'فراست',
  'فراسو',
  'فراغت',
  'فرانس',
  'فرانک',
  'فرانو',
  'فراهم',
  'فربهی',
  'فرتوت',
  'فرتور',
  'فرجاد',
  'فرجام',
  'فرجود',
  'فرداد',
  'فردار',
  'فردوس',
  'فردیت',
  'فردید',
  'فرزان',
  'فرزند',
  'فرزنی',
  'فرزین',
  'فرسار',
  'فرسته',
  'فرستی',
  'فرشته',
  'فرضیه',
  'فرفره',
  'فرفری',
  'فرقان',
  'فرگرد',
  'فرمان',
  'فرموک',
  'فرمول',
  'فرمیک',
  'فرنام',
  'فرنگی',
  'فرنود',
  'فرهنگ',
  'فروتر',
  'فروتن',
  'فروزش',
  'فروسو',
  'فروش‌',
  'فروش"',
  'فروشی',
  'فروکش',
  'فروهر',
  'فروید',
  'فرویش',
  'فریاد',
  'فریبا',
  'فریبی',
  'فریزر',
  'فریسی',
  'فزونی',
  'فستوک',
  'فسفات',
  'فسفره',
  'فسفری',
  'فسیله',
  'فسیلی',
  'فشاری',
  'فشانی',
  'فشردن',
  'فشرده',
  'فشفاش',
  'فشفشه',
  'فشنگی',
  'فصاحت',
  'فضائی',
  'فضاحت',
  'فضایی',
  'فضولا',
  'فضولی',
  'فضیحت',
  'فضیلت',
  'فطانت',
  'فط‌ری',
  'فط‌یر',
  'فعالی',
  'فعلاً',
  'فعلگی',
  'فقدات',
  'فقدان',
  'فقرات',
  'فکاهی',
  'فکوری',
  'فلاحت',
  'فلاخن',
  'فلادی',
  'فلاسک',
  'فلافل',
  'فلاکت',
  'فلانل',
  'فلانی',
  'فلزات',
  'فلسفه',
  'فلسفی',
  'فلفلی',
  'فلکشن',
  'فلهای',
  'فلوکس',
  'فنجان',
  'فندقک',
  'فندقه',
  'فندقی',
  'فندگی',
  'فنیقی',
  'فهرست',
  'فواحش',
  'فواره',
  'فواصل',
  'فوبیک',
  'فوتون',
  'فوراً',
  'فوران',
  'فوریت',
  'فوریه',
  'فوکوس',
  'فولات',
  'فولاد',
  'فولرن',
  'فولمی',
  'فولوس',
  'فولیک',
  'فونیک',
  'فیبری',
  'فیدبک',
  'فیروز',
  'فیزیک',
  'فیصله',
  'فیکوس',
  'فیلتر',
  'فیلیپ',
  'فیلین',
  'قائمه',
  'قائمی',
  'قابلت',
  'قابله',
  'قابیل',
  'قاپوق',
  'قاتمه',
  'قاچاق',
  'قارچی',
  'قارهء',
  'قاشقک',
  'قاشقی',
  'قاصدک',
  'قاطبه',
  'قاط‌ر',
  'قاط‌ع',
  'قاعده',
  'قافیه',
  'قاقله',
  'قالب‌',
  'قالبی',
  'قاموس',
  'قانون',
  'قاهره',
  'قایقی',
  'قباحت',
  'قبالا',
  'قباله',
  'قبایل',
  'قبراق',
  'قبرسی',
  'قبرکن',
  'قبولا',
  'قبولی',
  'قبیله',
  'قتاله',
  'قحبگی',
  'قحط‌ی',
  'قداره',
  'قداست',
  'قدامی',
  'قدرتی',
  'قدومه',
  'قدیفه',
  'قدیم‌',
  'قدیمه',
  'قدیمی',
  'قرائت',
  'قرائن',
  'قرابت',
  'قرابه',
  'قراضه',
  'قران‌',
  'قراول',
  'قراین',
  'قرطاس',
  'قرطبه',
  'قرقره',
  'قرقیز',
  'قرمزی',
  'قرنتی',
  'قرنفل',
  'قرنیز',
  'قرنیس',
  'قرنیه',
  'قروچه',
  'قریحه',
  'قرینه',
  'قزاقی',
  'قساوت',
  'قسمت‌',
  'قسمتی',
  'قشلاق',
  'قشنگی',
  'قشونی',
  'قصابی',
  'قصاصی',
  'قصهگو',
  'قصیده',
  'قضائی',
  'قضاوت',
  'قضایی',
  'قط‌بش',
  'قط‌بی',
  'قطرات',
  'قطران',
  'قط‌ره',
  'قط‌ری',
  'قطعاً',
  'قطعات',
  'قط‌عه',
  'قط‌عی',
  'قطعیت',
  'قطیفه',
  'قفقاز',
  'ققنوس',
  'قلابی',
  'قلاده',
  'قلدری',
  'قلعه‌',
  'قل‌قل',
  'قلمبه',
  'قلمرو',
  'قلمزن',
  'قلنبه',
  'قلندر',
  'قله‌ی',
  'قلومل',
  'قلیاب',
  'قلیان',
  'قلیای',
  'قلیلی',
  'قماری',
  'قمقمه',
  'قنادی',
  'قناری',
  'قناعت',
  'قنداق',
  'قندیل',
  'قنسول',
  'قهراً',
  'قهریه',
  'قهقرا',
  'قهقهه',
  'قواره',
  'قواعد',
  'قوط‌ی',
  'قولان',
  'قولنج',
  'قولون',
  'قومیت',
  'قوه‌ء',
  'قوه‌ی',
  'قیادت',
  'قیاسی',
  'قیافه',
  'قیامت',
  'قیراط',
  'قیصری',
  'قیصوم',
  'قیطان',
  'قیلوس',
  'قیماق',
  'قیمتی',
  'قیمه‌',
  'کابلی',
  'کابوس',
  'کابوی',
  'کابین',
  'کاتدی',
  'کاران',
  'کاربر',
  'کاربن',
  'کاربی',
  'کارتر',
  'کارتل',
  'کاردک',
  'کارکن',
  'کارگر',
  'کارگه',
  'کارلو',
  'کارها',
  'کاری‌',
  'کاریب',
  'کاریز',
  'کاریک',
  'کاساو',
  'کاسبی',
  'کاستن',
  'کاسته',
  'کاستی',
  'کاسنی',
  'کاشتن',
  'کاشته',
  'کاغذی',
  'کافتی',
  'کافکا',
  'کافور',
  'کاکله',
  'کاکلی',
  'کاکنج',
  'کالای',
  'کالبد',
  'کالری',
  'کامجو',
  'کامل‌',
  'کاملا',
  'کاموا',
  'کامیک',
  'کانال',
  'کانای',
  'کانتی',
  'کانگا',
  'کانند',
  'کانون',
  'کاهبن',
  'کاهشی',
  'کاهگل',
  'کاهلی',
  'کاهنی',
  'کاهوی',
  'کاواک',
  'کایاک',
  'کایلی',
  'کایوت',
  'کایوس',
  'کبابه',
  'کبابی',
  'کبالت',
  'کبرای',
  'کبریا',
  'کبریت',
  'کبوتر',
  'کبوده',
  'کبودی',
  'کبیره',
  'کبیسه',
  'کپسول',
  'کتابت',
  'کتابی',
  'کتانی',
  'کتمان',
  'کتنجک',
  'کتهای',
  'کتیبه',
  'کتیرا',
  'کثافت',
  'کجاوه',
  'کجروی',
  'کدبان',
  'کدخدا',
  'کدمرد',
  'کدهای',
  'کدورت',
  'کدویی',
  'کدیور',
  'کرارا',
  'کرامت',
  'کرانه',
  'کرانی',
  'کراهت',
  'کرایه',
  'کرباس',
  'کربلا',
  'کربنی',
  'کربور',
  'کربید',
  'کردار',
  'کردکه',
  'کردگی',
  'کردن‌',
  'کردن.',
  'کردند',
  'کردنی',
  'کرشمه',
  'کرکره',
  'کرکین',
  'کرگدن',
  'کرملی',
  'کرموش',
  'کره‌ی',
  'کروات',
  'کروبی',
  'کروکت',
  'کروکه',
  'کروکی',
  'کرومی',
  'کرونا',
  'کرونر',
  'کرویت',
  'کریشه',
  'کریکت',
  'کریول',
  'کزازی',
  'کژبین',
  'کسادی',
  'کسالت',
  'کسانی',
  'کسیرا',
  'کسیکه',
  'کشاکش',
  'کشاله',
  'کشاند',
  'کشباف',
  'کشپار',
  'کشتار',
  'کشتگر',
  'کشتی‌',
  'کشدار',
  'کشسان',
  'کشکک‌',
  'کشمشک',
  'کشمکش',
  'کشمیر',
  'کشنده',
  'کشندی',
  'کش‌ها',
  'کشوری',
  'کشویی',
  'کشیدن',
  'کشیده',
  'کشیشه',
  'کشیشی',
  'کفاره',
  'کفالت',
  'کفایت',
  'کفپوش',
  'کفتار',
  'کفتری',
  'کفرگو',
  'کفگیر',
  'کفنفس',
  'کلاچ‌',
  'کلارت',
  'کلارک',
  'کلاری',
  'کلاژن',
  'کلاسه',
  'کلاغک',
  'کلاغی',
  'کلافک',
  'کلاله',
  'کلامی',
  'کلاهک',
  'کلاهی',
  'کلتان',
  'کلرات',
  'کلرور',
  'کلریت',
  'کلرید',
  'کلریک',
  'کلرین',
  'کلسیت',
  'کلسیم',
  'کلفتی',
  'کلمات',
  'کلمبو',
  'کلمهء',
  'کلهشق',
  'کلوچه',
  'کلوخه',
  'کلیات',
  'کلیجه',
  'کلیدی',
  'کلیسا',
  'کلیسم',
  'کلیشه',
  'کلیمی',
  'کلیوی',
  'کمانش',
  'کمانک',
  'کمانه',
  'کمانی',
  'کمبها',
  'کمبود',
  'کمپوت',
  'کمپین',
  'کمحجم',
  'کمحرف',
  'کمخرد',
  'کمدین',
  'کمرنک',
  'کمرنگ',
  'کم‌رو',
  'کمزاد',
  'کمزور',
  'کم‌سو',
  'کمعرض',
  'کمعقل',
  'کمعمق',
  'کمقطر',
  'کم‌کم',
  'کمهوش',
  'کمیاب',
  'کمیته',
  'کمیسر',
  'کمینه',
  'کناره',
  'کناری',
  'کنانه',
  'کنایه',
  'کنایی',
  'کنتال',
  'کنترل',
  'کنتور',
  'کنجار',
  'کنجدی',
  'کندرو',
  'کندکن',
  'کندگی',
  'کندن‌',
  'کندوی',
  'کندیا',
  'کندیل',
  'کنراد',
  'کنسرت',
  'کنسرو',
  'کنسول',
  'کنشگر',
  'کنشور',
  'کنعان',
  'کنکاش',
  'کنکور',
  'کنگره',
  'کننده',
  'کنونه',
  'کنونی',
  'کنیاک',
  'کنیدی',
  'کنیسه',
  'کهانت',
  'که‌با',
  'که‌حد',
  'که‌در',
  'کهربا',
  'کهریز',
  'کهمدت',
  'کهنگی',
  'کهولت',
  'کوآلا',
  'کواچا',
  'کوارت',
  'کوارک',
  'کوالا',
  'کوپال',
  'کوپتر',
  'کوپله',
  'کوپنی',
  'کوپید',
  'کوپیل',
  'کوتاه',
  'کوتری',
  'کوچکی',
  'کوچگر',
  'کوچین',
  'کودات',
  'کودال',
  'کودتا',
  'کودکی',
  'کودنی',
  'کورار',
  'کوران',
  'کورتا',
  'کورسی',
  'کوروش',
  'کوشاب',
  'کوشاد',
  'کوفتن',
  'کوفته',
  'کوفتی',
  'کوکبه',
  'کوکتل',
  'کوکسی',
  'کوکلس',
  'کوکوی',
  'کولاژ',
  'کولاک',
  'کولمب',
  'کولون',
  'کولیت',
  'کولیس',
  'کولیک',
  'کولین',
  'کومور',
  'کومیس',
  'کوهان',
  'کوواز',
  'کویتی',
  'کویچه',
  'کویکر',
  'کویین',
  'کیاست',
  'کیالک',
  'کیانگ',
  'کیشوت',
  'کیفری',
  'کیفیت',
  'کیلوس',
  'کیموس',
  'کیمیا',
  'کیناز',
  'کینگو',
  'کینون',
  'کیهان',
  'کیوان',
  'کیوسک',
  'گابنی',
  'گاجره',
  'گاراژ',
  'گارسی',
  'گارنت',
  'گازها',
  'گاستر',
  'گالای',
  'گالری',
  'گالیک',
  'گامبا',
  'گاندی',
  'گاواژ',
  'گاوان',
  'گاورس',
  'گاونر',
  'گایاک',
  'گایوس',
  'گپسرا',
  'گجسته',
  'گچبری',
  'گچکار',
  'گدائی',
  'گدازش',
  'گدازه',
  'گدایی',
  'گذاری',
  'گذاشت',
  'گذران',
  'گذشتن',
  'گذشته',
  'گرائی',
  'گرافت',
  'گرامر',
  'گرامی',
  'گرانت',
  'گرانش',
  'گرانه',
  'گرانی',
  'گراور',
  'گرایش',
  'گرایی',
  'گرداب',
  'گردان',
  'گردبر',
  'گردشی',
  'گردنش',
  'گردنه',
  'گردنی',
  'گردون',
  'گردوی',
  'گردیم',
  'گرسنه',
  'گرفت‌',
  'گرفتن',
  'گرفته',
  'گرلین',
  'گرمای',
  'گرمکن',
  'گروپر',
  'گروده',
  'گروسو',
  'گروشن',
  'گروه‌',
  'گروهی',
  'گرویی',
  'گریان',
  'گریزی',
  'گریوت',
  'گریوه',
  'گزارش',
  'گزاره',
  'گزاری',
  'گزافه',
  'گزافی',
  'گزنده',
  'گزنون',
  'گزیدن',
  'گزیده',
  'گزیرش',
  'گزینش',
  'گزینه',
  'گساری',
  'گستاخ',
  'گسترش',
  'گستره',
  'گسستن',
  'گسسته',
  'گسیلی',
  'گ.ش.)',
  'گ.ش.-',
  'گشادن',
  'گشاده',
  'گشادی',
  'گشایش',
  'گشتار',
  'گشتگر',
  'گشتگی',
  'گشنگی',
  'گشنیز',
  'گشودن',
  'گشوده',
  'گفتار',
  'گفتگو',
  'گفتنی',
  'گلابی',
  'گلایه',
  'گلبرگ',
  'گلبول',
  'گلپار',
  'گلچین',
  'گلدار',
  'گلدان',
  'گلدهی',
  'گلرنگ',
  'گلرود',
  'گلزار',
  'گلسنگ',
  'گلفام',
  'گلکار',
  'گلگون',
  'گلگیر',
  'گلمیخ',
  'گل‌ها',
  'گلهای',
  'گله‌ی',
  'گلوتن',
  'گلوکز',
  'گلوله',
  'گلویی',
  'گلیال',
  'گمارش',
  'گمانه',
  'گمانی',
  'گمایش',
  'گمراه',
  'گمرکی',
  'گمزاد',
  'گمشدن',
  'گمشده',
  'گمنام',
  'گمیزش',
  'گمیزه',
  'گنتوم',
  'گنتیل',
  'گنجشک',
  'گنجشگ',
  'گنجفه',
  'گنجور',
  'گنداب',
  'گندزا',
  'گندگی',
  'گندمی',
  'گندنا',
  'گهگاه',
  'گهگیر',
  'گهولش',
  'گواتر',
  'گوارا',
  'گوارش',
  'گواهی',
  'گواوا',
  'گوتیک',
  'گوتیگ',
  'گودال',
  'گودی‌',
  'گورخر',
  'گورکک',
  'گورکن',
  'گوریل',
  'گوزنی',
  'گوشبر',
  'گوشت‌',
  'گوشته',
  'گوشتی',
  'گوشزد',
  'گوگرد',
  'گونیا',
  'گوهری',
  'گویال',
  'گویان',
  'گویچه',
  'گویند',
  'گویها',
  'گویوت',
  'گیاه‌',
  'گیاهک',
  'گیاهی',
  'گیپان',
  'گیتار',
  'گیران',
  'گیرند',
  'گیری‌',
  'گیلاس',
  'گیومه',
  'لئامت',
  'لائوس',
  'لااقل',
  'لاپاز',
  'لاتکس',
  'لاتیک',
  'لاتین',
  'لاچنگ',
  'لاخشه',
  'لادن‌',
  'لاروب',
  'لازمه',
  'لاغری',
  'لاغیر',
  'لافزن',
  'لاقید',
  'لاکتو',
  'لامپا',
  'لامسه',
  'لانست',
  'لاواژ',
  'لایحه',
  'لاینر',
  'لباده',
  'لباس‌',
  'لباسش',
  'لباسی',
  'لبالب',
  'لبخند',
  'لبریز',
  'لبلاب',
  'لبنان',
  'لبه‌ء',
  'لبهای',
  'لبه‌ی',
  'لبویی',
  'لپتون',
  'لپهای',
  'لتونی',
  'لجاجت',
  'لجاره',
  'لجباز',
  'لحظ‌ه',
  'لختگی',
  'لذایذ',
  'لذیذه',
  'لذیذی',
  'لردمن',
  'لردها',
  'لرزان',
  'لرزشی',
  'لزوجت',
  'لزوما',
  'لسوتو',
  'لشکری',
  'لطافت',
  'لط‌فا',
  'لط‌مه',
  'لط‌یف',
  'لطیفه',
  'لعابی',
  'لعنتی',
  'لغزان',
  'لفاظی',
  'لفاف‌',
  'لفافه',
  'لفظ‌ی',
  'لقاحی',
  'لکایی',
  'لکترن',
  'لکتین',
  'لکدار',
  'لک‌لک',
  'لکنتی',
  'لکه‌ی',
  'لگدزن',
  'لگنچه',
  'لمانی',
  'لمیدن',
  'لمیده',
  'لندوک',
  'لنگان',
  'لنگری',
  'ل‌هجه',
  'لوئیس',
  'لوازم',
  'لواطه',
  'لوایح',
  'لوبیا',
  'لوپوس',
  'لودگی',
  'لوران',
  'لوریس',
  'لوریک',
  'لوریل',
  'لوستر',
  'لوسمی',
  'لوسین',
  'لوط‌ی',
  'لولای',
  '.لوله',
  'لوندی',
  'لوهان',
  'لیاقت',
  'لیپاز',
  'لیپید',
  'لیتری',
  'لیتوم',
  'لیتیم',
  'لیچار',
  'لیخنس',
  'لیدار',
  'لیزری',
  'لیزین',
  'لیستر',
  'لیستی',
  'لیطری',
  'لیکور',
  'لی‌لی',
  'لیموی',
  'لیوان',
  'مائده',
  'مابقی',
  'مابین',
  'ماتحت',
  'ماترک',
  'ماتسو',
  'ماتیک',
  'ماجرا',
  'ماجرت',
  'ماحصل',
  'مادام',
  'مادری',
  'مادگی',
  'ماده‌',
  'مادون',
  'مادیت',
  'مادین',
  'ماذنه',
  'ماربر',
  'ماردر',
  'مارسی',
  'مارکت',
  'مارکس',
  'مارکی',
  'مارها',
  'ماروم',
  'مارون',
  'مازاد',
  'مازوت',
  'مازوی',
  'ماژور',
  'ماژول',
  'ماساژ',
  'ماسبق',
  'ماسرو',
  'ماسکت',
  'ماشرا',
  'ماشین',
  'ماضیه',
  'مافوق',
  'مافیا',
  'ماقبل',
  'ماک‌ل',
  'ماکول',
  'ماکیا',
  'ماگلو',
  'ماگنت',
  'مالزی',
  'ماله‌',
  'مالوف',
  'مالیه',
  'مامان',
  'ماموت',
  'مامور',
  'مانتو',
  'ماندن',
  'مانده',
  'مانعی',
  'مانکن',
  'مانند',
  'مانور',
  'مانوس',
  'مانیل',
  'ماهری',
  'ماهوت',
  'ماهور',
  'ماهون',
  'ماهیت',
  'مایچه',
  'مایعی',
  'مایکو',
  'مایلی',
  'ماینه',
  'مایوس',
  'مایوی',
  'مباحث',
  'مبادا',
  'مبادی',
  'مبارز',
  'مبارک',
  'مباشر',
  'مبالغ',
  'مباهی',
  'مبتدی',
  'مبتذل',
  'مبتکر',
  'مبتلا',
  'مبتنی',
  'مبتهج',
  'مبحث‌',
  'مبداء',
  'مبدلی',
  'مبرهن',
  'مبسوط',
  'مبلغی',
  'مبنای',
  'مبهوت',
  'متاثر',
  'متاخر',
  'متادب',
  'متادن',
  'متاسف',
  'متالم',
  'متانت',
  'متاهل',
  'متبحر',
  'متبرک',
  'متبسم',
  'متجدد',
  'متجسس',
  'متجلی',
  'متحجر',
  'متحده',
  'متحرک',
  'متحمل',
  'متحیر',
  'متخصص',
  'متخلف',
  'متخلل',
  'متداو',
  'متدیک',
  'متدین',
  'متذلل',
  'مترجم',
  'متردد',
  'مترسک',
  'مترسل',
  'مترشح',
  'مترصد',
  'مترقی',
  'متروک',
  'متریک',
  'متشبث',
  'متشتت',
  'متشخص',
  'متشرع',
  'متشکر',
  'متشکل',
  'متشنج',
  'متصدی',
  'متصلا',
  'متصلب',
  'متصور',
  'متصوف',
  'متضاد',
  'متضمن',
  'متظلم',
  'متعال',
  'متعجب',
  'متعدد',
  'متعدی',
  'متعرض',
  'متعصب',
  'متعفن',
  'متعلق',
  'متعهد',
  'متعین',
  'متغیر',
  'متفحص',
  'متفرق',
  'متفقا',
  'متفقی',
  'متفکر',
  'متقدم',
  'متقلب',
  'متکای',
  'متکبر',
  'متکفل',
  'متکلم',
  'متلکم',
  'متلهف',
  'متلون',
  'متمدن',
  'متمرد',
  'متمسک',
  'متمکن',
  'متملق',
  'متمول',
  'متنبه',
  'متنعم',
  'متنفذ',
  'متنفر',
  'متنوع',
  'متهای',
  'متهمی',
  'متهور',
  'متوجه',
  'متوحش',
  'متورق',
  'متورم',
  'متوسط',
  'متوسل',
  'متوطن',
  'متوفا',
  'متوفی',
  'متوقع',
  'متوقف',
  'متولد',
  'متولی',
  'متیلن',
  'متیله',
  'مثابه',
  'مثالی',
  'مثانه',
  'مثلاً',
  'مثلثی',
  'مثنوی',
  'مجاری',
  'مجازی',
  'مجاعه',
  'مجالس',
  'مجانی',
  'مجاهد',
  'مجاور',
  'مجبور',
  'مجتمع',
  'مجددا',
  'مجذوب',
  'مجذور',
  'مجرای',
  'مجروح',
  'مجریه',
  'مجسم‌',
  'مجسمه',
  'مجعول',
  'مجلسی',
  'مجمعه',
  'مجموع',
  'مجنون',
  'مجهول',
  'مجوسی',
  'مجوفی',
  'مجولا',
  'مچاله',
  'محابا',
  'محاجه',
  'محاذی',
  'محارب',
  'محارم',
  'محاسب',
  'محاسن',
  'محافظ',
  'محافل',
  'محاکم',
  'محبس‌',
  'محبوب',
  'محبوس',
  'محتاج',
  'محتاط',
  'محتال',
  'محترق',
  'محترم',
  'محتسب',
  'محتشم',
  'محتکر',
  'محتمل',
  'محتوا',
  'محتوی',
  'محجبه',
  'محجوب',
  'محجور',
  'محدود',
  'محذوف',
  'محراب',
  'محرکه',
  'محروم',
  'محزون',
  'محسوب',
  'محسوس',
  'محصنه',
  'محصور',
  'محصول',
  'محظور',
  'محظوظ',
  'محفظه',
  'محفوظ',
  'محققا',
  'محکم‌',
  'محکمه',
  'محکمی',
  'محکوم',
  'محلات',
  'محلول',
  'محمدی',
  'محمره',
  'محمول',
  'محوری',
  'محوطه',
  'محیات',
  'محیطی',
  'مخارج',
  'مخازن',
  'مخاطب',
  'مخاطی',
  'مخافت',
  'مخالف',
  'مخالی',
  'مختار',
  'مختال',
  'مخترع',
  'مختصر',
  'مختصه',
  'مختفی',
  'مختلج',
  'مختلس',
  'مختلط',
  'مختلف',
  'مختنق',
  'مختوم',
  'مخدره',
  'مخدوم',
  'مخربی',
  'مخروب',
  'مخروط',
  'مخزنی',
  'مخصوص',
  'مخط‌ط',
  'مخلوط',
  'مخلوع',
  'مخلوق',
  'مخمصه',
  'مخملک',
  'مخملی',
  'مخمور',
  'مخیله',
  'مداخل',
  'مدادی',
  'مدارا',
  'مدارج',
  'مدارس',
  'مدارک',
  'مداری',
  'مدافع',
  'مداقه',
  'مداوا',
  'مداوم',
  'مدتها',
  'مدرسه',
  'مدرسی',
  'مدرکی',
  'مدروز',
  'مدفوع',
  'مدفون',
  'مدلول',
  'مدنیت',
  'مدهوش',
  'مدوله',
  'مدیال',
  'مدیحه',
  'مدیره',
  'مدیکر',
  'مدینه',
  'مدیون',
  'مذبذب',
  'مذبور',
  'مذکور',
  'مذموم',
  'مذهبی',
  'مرائی',
  'مراتب',
  'مراتع',
  'مراجع',
  'مراحل',
  'مرارت',
  'مراسم',
  'مراقب',
  'مراکز',
  'مراکش',
  'مربای',
  'مربوط',
  'مرتاض',
  'مرتبا',
  'مرتبت',
  'مرتبط',
  'مرتبه',
  'مرتجع',
  'مرتعش',
  'مرتفع',
  'مرتکب',
  'مرتهن',
  'مرثیت',
  'مرثیه',
  'مرجان',
  'مرجعی',
  'مرجوع',
  'مرحبا',
  'مرحله',
  'مرحمت',
  'مرحوم',
  'مرخصی',
  'مرداب',
  'مردار',
  'مردان',
  'مردگی',
  'مردمک',
  'مردمی',
  'مردنی',
  'مردها',
  'مردود',
  'مردیا',
  'مرزیا',
  'مرسوم',
  'مرضعه',
  'مرطوب',
  'مرعوب',
  'مرغان',
  'مرغوب',
  'مرغوز',
  'مرفین',
  'مرکبی',
  'مرکزش',
  'مرکزی',
  'مرمری',
  'مرمکی',
  'مرموز',
  'مرهمی',
  'مرهون',
  'مریخ‌',
  'مریخی',
  'مریضی',
  'مزاجی',
  'مزاحم',
  'مزارع',
  'مزبله',
  'مزبور',
  'مزجات',
  'مزحزف',
  'مزخرف',
  'مزدوج',
  'مزدور',
  'مزرعه',
  'مزروع',
  'مزغان',
  'مزقان',
  'مزمزه',
  'مزمور',
  'مزه‌و',
  'مزیدن',
  'مژگان',
  'مسئله',
  'مسئول',
  'مسائل',
  'مساحت',
  'مساحی',
  'مساعد',
  'مساعی',
  'مسافت',
  'مسافر',
  'مساله',
  'مساوی',
  'مسایل',
  'مسبار',
  'مستبد',
  'مستتر',
  'مستحب',
  'مستحق',
  'مستدل',
  'مسترد',
  'مستعد',
  'مستغل',
  'مستقر',
  'مستقل',
  'مستمر',
  'مستمع',
  'مستند',
  'مستور',
  'مستوی',
  'مسحور',
  'مسخره',
  'مسدود',
  'مسرور',
  'مسط‌ح',
  'مسطحه',
  'مسعود',
  'مسکن‌',
  'مسکنت',
  'مسکوت',
  'مسکوک',
  'مسکون',
  'مسکیت',
  'مسکین',
  'مسلسل',
  'مسلکی',
  'مسلما',
  'مسلول',
  'مسموع',
  'مسموم',
  'مسنتر',
  'مسنجر',
  'مسندی',
  'مسواک',
  'مسوده',
  'مسیحی',
  'مسیری',
  'مشئوم',
  'مشابه',
  'مشارک',
  'مشاعر',
  'مشاعی',
  'مشاغل',
  'مشاور',
  'مشتاق',
  'مشتبه',
  'مشترک',
  'مشتری',
  'مشتعل',
  'مشتکل',
  'مشتمل',
  'مشتهی',
  'مشحون',
  'مشخصه',
  'مشخصی',
  'مشربی',
  'مشروب',
  'مشروح',
  'مشروط',
  'مشروع',
  'مشعشع',
  'مشعوف',
  'مشغله',
  'مشغول',
  'مشکوک',
  'مشمئز',
  'مشمول',
  'مشهود',
  'مشهور',
  'مشورت',
  'مشوند',
  'مشیتی',
  'مشیمه',
  'مصائب',
  'مصاحب',
  'مصادف',
  'مصالح',
  'مصداق',
  'مصدری',
  'مصرفی',
  'مصروع',
  'مصطبه',
  'مصطفی',
  'مصطکی',
  'مصطلح',
  'مصلحت',
  'مصلوب',
  'مصنوع',
  'مصوبه',
  'مصوری',
  'مصیبت',
  'مضاعف',
  'مضبوط',
  'مضحکی',
  'مضراب',
  'مضرس‌',
  'مضروب',
  'مضطرب',
  'مضمحل',
  'مضمون',
  'مضیقه',
  'مطابق',
  'مطالب',
  'مطبقه',
  'مطبوخ',
  'مطبوع',
  'مط‌رح',
  'مطرقه',
  'مطرود',
  'مط‌لا',
  'مط‌لب',
  'مطلبی',
  'مط‌لع',
  'مط‌لق',
  'مطلقا',
  'مطلقه',
  'مطلوب',
  'مطمئن',
  'مط‌ول',
  'مط‌یع',
  'مظلمه',
  'مظلوم',
  'مظ‌نه',
  'مظنون',
  'معابد',
  'معادل',
  'معادن',
  'معارض',
  'معارف',
  'معاشر',
  'معاصر',
  'معاضد',
  'معافی',
  'معالج',
  'معاند',
  'معانی',
  'معاون',
  'معبود',
  'معتاد',
  'معتبر',
  'معتدل',
  'معترض',
  'معترف',
  'معتقد',
  'معتقر',
  'معتکف',
  'معتمد',
  'معتنی',
  'معجزه',
  'معجون',
  'معدلت',
  'معدنی',
  'معدود',
  'معدوم',
  'معذرت',
  'معذور',
  'معراج',
  'معرفت',
  'معرفه',
  'معرفی',
  'معرکه',
  'معروف',
  'معزول',
  'معشوق',
  'معصوم',
  'معصون',
  'معصیت',
  'معط‌ر',
  'معط‌ل',
  'معطلی',
  'معطوف',
  'معقول',
  'معکوس',
  'معلمی',
  'معلول',
  'معلوم',
  'معمار',
  'معمای',
  'معمور',
  'معمول',
  'معنای',
  'معنوی',
  'معهذا',
  'معوقه',
  'معیار',
  'معیشت',
  'معینی',
  'معیوب',
  'مغاره',
  'مغازه',
  'مغایر',
  'مغبنی',
  'مغبون',
  'مغرور',
  'مغشوش',
  'مغفرت',
  'مغلوب',
  'مغلوط',
  'مغموم',
  'مغولی',
  'مفاصل',
  'مفتاح',
  'مفتخر',
  'مفتری',
  'مفتضح',
  'مفتکی',
  'مفتوح',
  'مفتول',
  'مفتون',
  'مفروش',
  'مفروض',
  'مفروق',
  'مفصلا',
  'مفصلی',
  'مفعول',
  'مفقود',
  'مفهوم',
  'مفیدی',
  'مقابل',
  'مقارن',
  'مقاصد',
  'مقاله',
  'مقاوم',
  'مقبره',
  'مقبول',
  'مقتدا',
  'مقتدر',
  'مقتصد',
  'مقتضی',
  'مقتول',
  'مقدار',
  'مقدس‌',
  'مقدسه',
  'مقدمه',
  'مقدور',
  'مقراض',
  'مقرره',
  'مقرری',
  'مقرون',
  'مقسوم',
  'مقصود',
  'مقط‌ع',
  'مقطعی',
  'مقطوع',
  'مقعدی',
  'مقلاد',
  'مقلدی',
  'مقنعه',
  'مقننه',
  'مقهور',
  'مقوای',
  'مقوله',
  'مقیاس',
  'مکاتب',
  'مکاره',
  'مکانت',
  'مکانی',
  'مکتبی',
  'مکتشف',
  'مکتوب',
  'مکتوم',
  'مکثار',
  'مکررا',
  'مکروه',
  'مکزیک',
  'مکشوف',
  'مکنده',
  'مکنون',
  'مکونگ',
  'مکیدن',
  'مگاتن',
  'مگرهم',
  'مگنوم',
  'ملاحت',
  'ملازم',
  'ملازه',
  'ملازی',
  'ملافه',
  'ملاقه',
  'ملالت',
  'ملامت',
  'ملایم',
  'ملبوس',
  'ملتزم',
  'ملتفت',
  'ملتمس',
  'ملتهب',
  'ملزوم',
  'ملعبه',
  'ملعقه',
  'ملعون',
  'ملقمه',
  'ملکول',
  'ملکی‌',
  'ملموس',
  'ملوان',
  'ملودی',
  'ملونه',
  'ملیله',
  'ملیمت',
  'ملیون',
  'م.م.)',
  'مماسی',
  'ممالک',
  'ممتاز',
  'ممتحن',
  'ممتلی',
  'ممتنع',
  'ممزوج',
  'مملکت',
  'مملوک',
  'ممنوع',
  'ممنون',
  'ممیزه',
  'ممیزی',
  'منابع',
  'منادی',
  'مناره',
  'منازع',
  'منازل',
  'مناسب',
  'مناسک',
  'مناصب',
  'مناطق',
  'مناظر',
  'مناعت',
  'منافذ',
  'منافع',
  'منافق',
  'منافی',
  'منامه',
  'منایش',
  'منبسط',
  'منتجه',
  'منتخب',
  'منتزع',
  'منتسب',
  'منتشر',
  'منتصر',
  'منتظر',
  'منتظم',
  'منتفع',
  'منتفی',
  'منتقد',
  'منتقل',
  'منتها',
  'منتهی',
  'منجمد',
  'منجوق',
  'منحرف',
  'منحصر',
  'منحنی',
  'منحوس',
  'منداب',
  'مندرج',
  'مندرس',
  'مندیل',
  'منزجر',
  'منزلت',
  'منزوی',
  'منسجم',
  'منسوب',
  'منسوج',
  'منسوخ',
  'منشاء',
  'منشعب',
  'منشور',
  'منصرف',
  'منصفه',
  'منصوب',
  'منصور',
  'منطبق',
  'منطفی',
  'منط‌ق',
  'منطقه',
  'منطقی',
  'منظ‌ر',
  'منظره',
  'منظ‌م',
  'منظور',
  'منظوم',
  'منعقد',
  'منعکس',
  'منفجر',
  'منفرج',
  'منفرد',
  'منفصل',
  'منفعت',
  'منفعل',
  'منفور',
  'منفیت',
  'منقاد',
  'منقار',
  'منقبت',
  'منقبض',
  'منقرض',
  'منقسم',
  'منقصت',
  'منقضی',
  'منقطع',
  'منقلب',
  'منقوش',
  'منقوط',
  'منقول',
  'منکسر',
  'منکوب',
  'منگان',
  'منگنز',
  'منگنه',
  'من‌من',
  'مننده',
  'منهای',
  'منهدم',
  'منهوت',
  'منهوک',
  'منوال',
  'منیزی',
  'منیعی',
  'مهابت',
  'مهاجر',
  'مهاجم',
  'مهارت',
  'مهبلی',
  'مهپاش',
  'مهتاب',
  'مهجور',
  'مهراد',
  'مهراز',
  'مهران',
  'مهرهء',
  'مهریه',
  'مهمات',
  'مهمان',
  'مهمتر',
  'مهملی',
  'مهموم',
  'مهمیز',
  'مهندس',
  'مهندی',
  'مهیبی',
  'مه‌یخ',
  'مواجب',
  'مواجه',
  'مواخه',
  'موادی',
  'موارد',
  'موازی',
  'مواضع',
  'مواظب',
  'موافق',
  'مواقع',
  'موانع',
  'موبری',
  'موبور',
  'موبوط',
  'موتلف',
  'موتمن',
  'موتور',
  'موثره',
  'موجبر',
  'موجود',
  'موچسب',
  'موچین',
  'مودار',
  'موران',
  'مورای',
  'موربی',
  'مورچه',
  'موردی',
  'مورفی',
  'موروث',
  'موریس',
  'موزار',
  'موزنی',
  'موزون',
  'موزیک',
  'موسسه',
  'موسقی',
  'موسلی',
  'موسوم',
  'موسوی',
  'موسیر',
  'موشکی',
  'موصلی',
  'موصول',
  'موضعی',
  'موضوع',
  'موط‌ن',
  'موعظه',
  'موعود',
  'موقتا',
  'موقتی',
  'موقعی',
  'موقوف',
  'موکول',
  'مولتی',
  'مولفه',
  'مولکل',
  'مولود',
  'مومیا',
  'مونته',
  'موهاک',
  'موهای',
  'موهبت',
  'موهوم',
  'مویرگ',
  'مویین',
  'میآید',
  'میانه',
  'میانی',
  'میاید',
  'میبرد',
  'میتند',
  'میثاق',
  'میخچه',
  'میخکی',
  'میخوش',
  'میدار',
  'میدان',
  'میدهد',
  'میراث',
  'میرسد',
  'میرود',
  'میزان',
  'میزند',
  'میسار',
  'میسور',
  'میسون',
  'می‌شد',
  'میشده',
  'میشود',
  'میعاد',
  'میعان',
  'میکای',
  'میکده',
  'میکرب',
  'میکرو',
  'میکسر',
  'میکشد',
  'میکند',
  'میگرن',
  'میگوی',
  'میلاد',
  'میلان',
  'میلوم',
  'میلون',
  'میلین',
  'میمکد',
  'میمنت',
  'میمون',
  'مینای',
  'مینسک',
  'مینور',
  'مینوی',
  'میهنی',
  'می‌ید',
  'ناامن',
  'ناباب',
  'نابجا',
  'نابغه',
  'نابود',
  'ناپاک',
  'ناتال',
  'ناتنی',
  'ناجنس',
  'ناجور',
  'ناچار',
  'ناچیز',
  'ناحوم',
  'ناحیت',
  'ناحیه',
  'ناخدا',
  'ناخنک',
  'ناخود',
  'ناخوش',
  'نادار',
  'نادان',
  'نارسا',
  'نارسی',
  'نارنج',
  'نارنگ',
  'ناروا',
  'نارون',
  'نازکی',
  'نازله',
  'ناساز',
  'ناسره',
  'ناسزا',
  'ناسور',
  'ناشاد',
  'ناشتا',
  'ناشخص',
  'ناشده',
  'ناشکر',
  'ناصاف',
  'ناصره',
  'ناصیه',
  'ناط‌ق',
  'ناظ‌ر',
  'ناظ‌م',
  'ناعور',
  'نافصل',
  'ناقلا',
  'ناقوس',
  'ناکام',
  'ناکسی',
  'ناکنش',
  'ناکوک',
  'ناگاه',
  'ناگذر',
  'نالان',
  'نامرد',
  'نامزد',
  'نامهء',
  'نامور',
  'ناموس',
  'نامید',
  'نانپز',
  'نانوا',
  'ناهار',
  'ناهید',
  'ناوبر',
  'ناوچه',
  'ناورد',
  'ناوقت',
  'ناوها',
  'ناویا',
  'نایاب',
  'نایچه',
  'نایژک',
  'نایژه',
  'نباتی',
  'نباشد',
  'نبشته',
  'نبوتی',
  'نبودن',
  'نبیره',
  'نپال‌',
  'نپالی',
  'نپتون',
  'نپخته',
  'نت‌ها',
  'نتوان',
  'نتیجه',
  'نجابت',
  'نجارت',
  'نجاره',
  'نجاری',
  'نجاست',
  'نجاشی',
  'نجباء',
  'نجومی',
  'نحوست',
  'نحیفی',
  'نخاعی',
  'نختاب',
  'نخجیر',
  'نخستی',
  'نخودی',
  'نخورد',
  'نخینه',
  'ندادن',
  'ندارد',
  'نداره',
  'نداشت',
  'ندامت',
  'نداند',
  'نداوت',
  'ندایی',
  'ندیده',
  'ندیمه',
  'نردهء',
  'نرفته',
  'نرمال',
  'نرمان',
  'نرمخو',
  'نرمدل',
  'نروژی',
  'نریان',
  'نرینه',
  'نزاری',
  'نزاکت',
  'نزدیک',
  'نزولی',
  'نژاده',
  'نژادی',
  'نژندی',
  'نساجی',
  'نسبتا',
  'نسبته',
  'نسبیت',
  'نسترن',
  'نسلها',
  'نسناس',
  'نسوان',
  'نسیان',
  'نسیمی',
  'نشانه',
  'نشانی',
  'نشدنی',
  'نشریه',
  'نشستن',
  'نشسته',
  'نشستی',
  'نشناس',
  'نشوید',
  'نشیمن',
  'نشیند',
  'نشینی',
  'نصحیت',
  'نصیحت',
  'نط‌فه',
  'نظارت',
  'نظاره',
  'نظافت',
  'نظامی',
  'نظ‌ری',
  'نظریه',
  'نظ‌یر',
  'نظ‌یف',
  'نعمان',
  'نعناع',
  'نفاسی',
  'فاطمه',
  'نفرات',
  'نفربر',
  'نفریت',
  'نفرین',
  'نفلین',
  'نفوذی',
  'نقاره',
  'نقاشی',
  'نقاضت',
  'نقاطی',
  'نقاله',
  'نقالی',
  'نقاهت',
  'نقدشو',
  'نقدگر',
  'نقدنو',
  'نقرسی',
  'نقصان',
  'نقط‌ه',
  'نقطهء',
  'نقلیه',
  'نقیصه',
  'نکاحی',
  'نکردم',
  'نکردن',
  'نکرده',
  'نکروز',
  'نکوهش',
  'نکویی',
  'نگارش',
  'نگاره',
  'نگاری',
  'نگاشت',
  'نگاهی',
  'نگران',
  'نگرشی',
  'نگفتن',
  'نگینی',
  'نلسون',
  'نمائی',
  'نمادی',
  'نمارش',
  'نمازی',
  'نماید',
  'نمایش',
  'نمایه',
  'نمایی',
  'نمدار',
  'نمسار',
  'نمکها',
  'نمکین',
  'نمگین',
  'نمناک',
  'نم‌نم',
  'نمودن',
  'نموده',
  'نمونه',
  'ننجون',
  'ننگین',
  'نهائی',
  'نهادن',
  'نهاده',
  'نهادی',
  'نهانی',
  'نهایت',
  'نهایی',
  'نهشتن',
  'نهشته',
  'نهشتی',
  'نهفتن',
  'نهفته',
  'نهمین',
  'نه‌نه',
  'نوآور',
  'نواحی',
  'نواخت',
  'نوادر',
  'نواده',
  'نواره',
  'نواری',
  'نوازش',
  'نوازی',
  'نواقل',
  'نواله',
  'نواور',
  'نوبتی',
  'نودار',
  'نورده',
  'نوردی',
  'نورکم',
  'نورند',
  'نورون',
  'نوریت',
  'نوزاد',
  'نوزده',
  'نوساز',
  'نوسان',
  'نوشتن',
  'نوشته',
  'نوشند',
  'نوشین',
  'نوکری',
  'نوگرا',
  'نومید',
  'نونهء',
  'نوه‌ی',
  'نویسه',
  'نویسی',
  'نوینی',
  'نیابت',
  'نیام"',
  'نیامد',
  'نیامه',
  'نیامی',
  'نیایش',
  'نیترو',
  'نیجری',
  'نیران',
  'نیرنگ',
  'نیروی',
  'نیزار',
  'نیستی',
  'نیشتر',
  'نیشکر',
  'نیگرا',
  'نیمرخ',
  'نیمرو',
  'نیمکت',
  'نینجا',
  'نی‌نی',
  'نیوتن',
  'هااست',
  'هابیت',
  'هاتان',
  'هاتیر',
  'هارلم',
  'هارون',
  'هازان',
  'هاسیم',
  'هاشور',
  'هاضمه',
  'هافبک',
  'هاگزا',
  'هالتر',
  'هالون',
  'هالید',
  'هامون',
  'هانری',
  'هانوی',
  'هاوشی',
  'هاویه',
  '-‌های',
  'هپتان',
  'هپروت',
  'هجائی',
  'هجاکم',
  'هجایی',
  'هجدهم',
  'هجران',
  'هجومی',
  'هدایت',
  'هدفون',
  'هذیان',
  'هراتی',
  'هراسی',
  'هرچیز',
  'هرروز',
  'هرزگی',
  'هرسال',
  'هرسکن',
  'هرکجا',
  'هرکول',
  'هرگاه',
  'هرمتر',
  'هرنوع',
  'هر‌یک',
  'هزارم',
  'هزاره',
  'هزیمت',
  'هزینه',
  'هژمون',
  'هستار',
  'هستند',
  'هشتاد',
  'هشدار',
  'هشیار',
  'هفتاد',
  'هفتگی',
  'هفدهم',
  'هکتار',
  'هکتور',
  'هگزان',
  'هگزوز',
  'هلاکت',
  'هلالی',
  'هلاهل',
  'هلندی',
  'هلهله',
  'هلویی',
  'هلیله',
  'هلیوم',
  'هماثر',
  'همادی',
  'همارز',
  'هماره',
  'همانا',
  'همایش',
  'همباز',
  'همبخش',
  'همبند',
  'همجنس',
  'همچشم',
  'همچند',
  'همچون',
  'هم‌حس',
  'همدرد',
  'همدست',
  'همدلی',
  'همدما',
  'همدمی',
  'همدیس',
  'همراز',
  'همراس',
  'همراه',
  'همرای',
  'همرزم',
  'همرفت',
  'همرنگ',
  'همریز',
  'همزاد',
  'همزدن',
  'همساز',
  'همسال',
  'همسان',
  'همستر',
  'همستی',
  'همسرش',
  'همسری',
  'همسطح',
  'همسفر',
  'همسنگ',
  'همشغل',
  'همشید',
  'همصدا',
  'همعصر',
  'همفکر',
  'همکار',
  'هم‌کف',
  'همگام',
  'همگان',
  'همگاه',
  'همگرا',
  'همگون',
  'همنوا',
  'همنوع',
  'همهمه',
  'همه‌ی',
  'هموار',
  'هموزن',
  'هموژن',
  'هموقت',
  'همیشه',
  'همینگ',
  'هنایا',
  'هنایش',
  'هنباز',
  'هنجار',
  'هندسه',
  'هندسی',
  'هنرها',
  'هنرور',
  'هنگام',
  'هنگفت',
  'هوائی',
  'هوازی',
  'هواکش',
  'هوانگ',
  'هوایی',
  'هوبره',
  'هورمن',
  'هورنت',
  'هوفام',
  'هوگان',
  'هومیک',
  'هویدا',
  'هویزه',
  'هیالن',
  'هیالو',
  'هیاهو',
  'هیتمن',
  'هیجان',
  'هیجده',
  'هیچکس',
  'هیمنه',
  'هینگد',
  'هیولا',
  'وابجو',
  'وابشت',
  'واپاد',
  'واجبی',
  'واحدو',
  'واحدی',
  'واخلا',
  'واداب',
  'وادار',
  'وادبی',
  'وارام',
  'واران',
  'وارثه',
  'وارده',
  'وارسی',
  'وارگی',
  'وارنا',
  'وارون',
  'واریب',
  'واریز',
  'واریس',
  'وازدن',
  'وازده',
  'واساس',
  'واسطه',
  'واسیا',
  'واسیب',
  'واصلا',
  'واصلی',
  'واصول',
  'واضحا',
  'واعلا',
  'وافرش',
  'واقعا',
  'واقعه',
  'واقعی',
  'واکسن',
  'واکسی',
  'واکمن',
  'واکنش',
  'واکنی',
  'واگرا',
  'واگشت',
  'واگن‌',
  'واگنر',
  'واگنی',
  'واگون',
  'واگیر',
  'والان',
  'والتا',
  'والتر',
  'والتز',
  'والده',
  'والکل',
  'والوچ',
  'واندک',
  'وانرا',
  'وانیل',
  'واهمه',
  'واهنگ',
  'واهی‌',
  'واواز',
  'وباخت',
  'وباطل',
  'وبایی',
  'وبخار',
  'وبخور',
  'وبدنه',
  'وبدنی',
  'وبدون',
  'وبرای',
  'وبرتن',
  'وبرخی',
  'وبرهم',
  'وبزرگ',
  'وبعمل',
  'وبلاگ',
  'وبلدی',
  'وبلند',
  'وبلوز',
  'وبوته',
  'وپاسخ',
  'وپنبه',
  'وپنیر',
  'وپهلو',
  'وپوره',
  'وپوست',
  'وپوشش',
  'وپیاز',
  'وپیرو',
  'وپیری',
  'وپیزر',
  'وتاثر',
  'وتروق',
  'وتصوف',
  'وتفکر',
  'وتفوق',
  'وتلفظ',
  'وتلفن',
  'وتمتع',
  'وتندی',
  'وتهیه',
  'وتوجه',
  'وتیزی',
  'وثبات',
  'وثیقه',
  'وجادو',
  'وجامد',
  'وجاهت',
  'وجبری',
  'وجدان',
  'وجدید',
  'وجنبش',
  'وجنگی',
  'وجنوب',
  'وجنون',
  'وجواب',
  'وجوجه',
  'وجودی',
  'وچابک',
  'وچروک',
  'وحرکت',
  'وحشی‌',
  'وحمله',
  'وحیاط',
  'وخارج',
  'وخامه',
  'وخدعه',
  'وخراب',
  'وخرده',
  'وخروج',
  'وخروس',
  'وخروش',
  'وخسته',
  'وخشور',
  'وخفت‌',
  'وخمیر',
  'وخواص',
  'وخورد',
  'وخیال',
  'وخیلی',
  'وداعی',
  'ودراز',
  'ودرست',
  'ودرهم',
  'ودزدی',
  'ودسته',
  'ودستی',
  'ودعای',
  'ودفاع',
  'ودهان',
  'ودودل',
  'ودولت',
  'ودیعه',
  'وذیفن',
  'ورئیس',
  'وراثت',
  'وراجی',
  'وراست',
  'ورجنز',
  'وردست',
  'وردنه',
  'ورزدن',
  'ورزشی',
  'ورسک‌',
  'ورسوم',
  'ورشدن',
  'ورشده',
  'ورشیم',
  'ورقیق',
  'ورموت',
  'ورهنی',
  'وروحی',
  'ورودی',
  'وروزه',
  'وروشن',
  'ورونا',
  'ورویه',
  'وریدی',
  'وریشه',
  'وزارت',
  'وزرده',
  'وزمان',
  'وزمخت',
  'وزنده',
  'وزنه‌',
  'وزیبا',
  'وزیدن',
  'وزیری',
  'وزینت',
  'وسائط',
  'وسائل',
  'وساطت',
  'وسایر',
  'وسایط',
  'وسایل',
  'وسایه',
  'وسبزه',
  'وسبک‌',
  'وسرعت',
  'وسرکه',
  'وسط‌ی',
  'وسطی‌',
  'وسفید',
  'وسلی‌',
  'وسواس',
  'وسوسه',
  'وسیاه',
  'وسیع‌',
  'وسیعی',
  'وسیله',
  'وشارع',
  'وشانه',
  'وشاهی',
  'وشاید',
  'وشدید',
  'وشربت',
  'وشریک',
  'وشگفت',
  'وشناد',
  'وشیره',
  'وشیشه',
  'وشیلی',
  'وشیوخ',
  'وصال‌',
  'وصایا',
  'وصایت',
  'وصحنه',
  'وصحیح',
  'وصدای',
  'وصولی',
  'وضعیت',
  'وط‌لب',
  'وط‌نی',
  'وظاهر',
  'وظایف',
  'وظیفه',
  'وعایق',
  'وعجیب',
  'وعرشه',
  'وعرفی',
  'وعط‌ر',
  'وعمده',
  'وعیسی',
  'وغدغد',
  'وغذای',
  'وغریب',
  'وغیره',
  'وفحاش',
  'وفرعی',
  'وفروش',
  'وفشار',
  'وفقیر',
  'وفکری',
  'وقابل',
  'وقاحت',
  'وقارچ',
  'وقاطر',
  'وقایت',
  'وقایع',
  'وقمار',
  'وکاسه',
  'وکالت',
  'وکامل',
  'وکتان',
  'وکردن',
  'وکسان',
  'وکشتی',
  'وکلمه',
  'وکمان',
  'وکمیک',
  'وکنده',
  'وکوچک',
  'وکودک',
  'وکودن',
  'وکیلی',
  'وکیوم',
  'وگران',
  'وگردن',
  'وگردی',
  'وگرنه',
  'وگنده',
  'وگوشت',
  'وگوشه',
  'وگونه',
  'ولادت',
  'ولایت',
  'ولباس',
  'ولتاژ',
  'ولخرج',
  'ولگرد',
  'ولنگر',
  'ولهجه',
  'ولوچه',
  'ولوله',
  'ولیمه',
  'ومادر',
  'وماده',
  'ومادی',
  'ومالت',
  'ومالش',
  'وماهی',
  'ومایه',
  'ومثبت',
  'ومجلل',
  'ومحبت',
  'ومحقق',
  'ومخدر',
  'ومردم',
  'ومردی',
  'ومرکز',
  'ومسطح',
  'ومسکن',
  'ومشجر',
  'ومشخص',
  'ومضحک',
  'ومعطر',
  'ومعوج',
  'ومعین',
  'ومغذی',
  'ومقام',
  'وملین',
  'ومنجم',
  'ومنزل',
  'ومنشا',
  'ومهذب',
  'ومواد',
  'وموجد',
  'وموزه',
  'ومومی',
  'ومیان',
  'ومیوه',
  'وناخن',
  'ونازک',
  'وناشی',
  'ونحوی',
  'ونرمی',
  'ونروژ',
  'ونژاد',
  'ونسار',
  'ونسان',
  'ونصیب',
  'ونقاط',
  'ونقره',
  'ونگار',
  'ونورس',
  'ونیمه',
  'وهرام',
  'وهرسه',
  'وهمی‌',
  'ووحشت',
  'وورزش',
  'وورود',
  'ووقفه',
  'ویدئو',
  'ویدیو',
  'ویراژ',
  'ویراق',
  'ویران',
  'ویروس',
  'ویزای',
  'ویزیت',
  'ویژگی',
  'ویسکی',
  'ویکجا',
  'ویکنت',
  'ویلان',
  'ویلسن',
  'ویلون',
  'وینیل',
  'ویولا',
  'ویولن',
  'ویوله',
  'یااثر',
  'یابرگ',
  'یابند',
  'یابها',
  'یاترد',
  'یاجاه',
  'یاجسم',
  'یاجشن',
  'یاجمع',
  'یاچسب',
  'یاچوب',
  'یاچیز',
  'یاچین',
  'یاحبی',
  'یاخته',
  'یاخرد',
  'یاخرس',
  'یاخشک',
  'یاخلط',
  'یادرد',
  'یادست',
  'یادسر',
  'یادکه',
  'یاذره',
  'یاربع',
  'یاردا',
  'یاردی',
  'یارمز',
  'یاریا',
  'یازخم',
  'یازدن',
  'یازده',
  'یاسقف',
  'یاسمن',
  'یاسند',
  'یاسیم',
  'یاشدن',
  'یاشنی',
  'یاصدف',
  'یاغرض',
  'یاغله',
  'یاغیر',
  'یافتن',
  'یافته',
  'یافخذ',
  'یاقوت',
  'یاقوی',
  'یاکلا',
  'یاکله',
  'یاگوش',
  'یالال',
  'یامحل',
  'یاملا',
  'یاموی',
  'یامیم',
  'یانکی',
  'یانیم',
  'یاهیچ',
  'یاوری',
  'یاویک',
  'یایکی',
  'یبوست',
  'یتیمی',
  'یخبرف',
  'یخچال',
  'یخدان',
  'یخسنگ',
  'یخهای',
  'یددار',
  'یرقان',
  'یزدان',
  'یساول',
  'یعقوب',
  'یقه‌ی',
  'یقینا',
  'یکایک',
  'یکبار',
  'یکبری',
  'یک‌جا',
  'یکجور',
  'یکدست',
  'یکدلی',
  'یک‌دم',
  'یکدمه',
  'یکدهم',
  'پدرام',
  'یکسان',
  'یکسره',
  'یکصدم',
  'یکمین',
  'یکنفر',
  'یکنوع',
  'یکوری',
  'یک‌یک',
  'یگانگ',
  'یگانه',
  'یگانی',
  'یلانگ',
  'یمانی',
  'ینبوع',
  'یهودا',
  'یهودی',
  'یهوه‌',
  'یوئیل',
  'یوحنا',
  'یورغه',
  'یوط‌ی',
  'یوکای',
  'یولاف',
  'یومیه',
  'یونان',
  'یونجه',
  'یوهان',
  'همراز',
  'مشهور',
  'مستند',
  'ضابطه',
  'قاهره',
  'فرفره',
  'غربال',
  'همراه',
  'خواهر',
  'حوصله',
  'تواضع',
  'سبلان',
  'برادر',
  'امسال',
  'خرداد',
  'دیروز',
  'عشایر',
  'نوروز',
  'همدان',
]